
<template>
  <div>
    <v-card>
      <v-card-title
        >Lista pagos

        <v-btn icon @click="cargarDatos()" color="green" :loading="cargandoDatos" :disabled="cargandoDatos">
          <v-icon>{{ icons.mdiCached }}</v-icon>
        </v-btn>
      </v-card-title>
      <v-card-text>
        <v-row>
          <v-col cols="12" md="4">
            <dos-fechas
              :fechas="[
                FuncionesGenerales.formatoFecha(filtros.fecha.desde, 4),
                FuncionesGenerales.formatoFecha(filtros.fecha.hasta, 4),
              ]"
              @getFechas="getFechas"
              :icon="true"
            ></dos-fechas>
          </v-col>
          <v-col cols="12" md="4">
            <buscar-cliente @Getcliente="getCliente" :icon="true"></buscar-cliente>
          </v-col>
          <v-col cols="10" md="3">
            <buscar-empleado @Getdatos="getEmpleado" :icon="true"></buscar-empleado>
          </v-col>
          <v-col cols="2" md="1">
            <v-btn icon @click="cargarDatos()" color="primary" :loading="cargandoDatos" :disabled="cargandoDatos">
              <v-icon>{{ icons.mdiMagnify }}</v-icon>
            </v-btn>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" v-if="Lista.length > 0">
            <v-text-field
              v-model="search"
              :append-icon="icons.mdiMagnify"
              label="Filtrar"
              single-line
              hide-details
              dense
              outlined
            ></v-text-field>
          </v-col>
        </v-row>
      </v-card-text>

      <!-- Table -->
      <v-data-table
        :headers="headers"
        :items="Lista"
        :search="search"
        :items-per-page="5"
        :loading="ListaLoad"
        class="table-kitchen-sink"
      >
        <template #[`item.id`]="{ item }">
          <strong class="text-no-wrap">#{{ item.id }} </strong>
          <br />
          <small class="text-no-wrap">{{ FuncionesGenerales.formatoFecha(item.fecha, 3) }}</small>
        </template>

        <template #[`item.grupo`]="{ item }">
          {{ item.grupo_negocio_json.descripcion }}
        </template>
        <template #[`item.cliente`]="{ item }">
          {{ item.cliente_json.nombre }} {{ item.cliente_json.apellido }}
        </template>
        <template #[`item.empleado`]="{ item }">
          {{ item.empleado_json.nombre }} {{ item.empleado_json.apellido }}
        </template>
        <template #[`item.monto_total`]="{ item }"> ${{ item.monto_total }} </template>
        <template #[`item.accion`]="{ item }">
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <a class="pr-2" v-bind="attrs" v-on="on" @click="GetItem(item)">
                <v-icon>{{ icons.mdiEye }}</v-icon>
              </a>
            </template>
            <span>Ver</span>
          </v-tooltip>
        </template>
      </v-data-table>
    </v-card>
  </div>
</template>
  
<script>
import { onBeforeMount, ref, context } from '@vue/composition-api'
import {
  mdiMagnify,
  mdiDeleteOutline,
  mdiStoreEdit,
  mdiPencilOutline,
  mdiAccountCancel,
  mdiAccountEdit,
  mdiAccountCheck,
  mdiCheck,
  mdiCancel,
  mdiStoreEditOutline,
  mdiPencil,
  mdiCached,
  mdiEye,
} from '@mdi/js'
import goTo from 'vuetify/lib/services/goto'
import PuestoService from '@/api/servicios/PuestoService'
import config from '@/api/config'
import ComandaService from '@/api/servicios/ComandaService'
import { now } from 'moment'
import BuscarCliente from '../../../archivo/cliente/components/BuscarCliente.vue'
import BuscarEmpleado from '../../../archivo/empleado/components/BuscarEmpleado.vue'
import DosFechas from '@/components/DosFechas.vue'
import FuncionesGenerales from '@/funciones/funciones'
import store from '@/store'
export default {
  components: { BuscarCliente, BuscarEmpleado, DosFechas },
  setup(props, context) {
    const search = ref('')
    const Lista = ref([])
    const ListaLoad = ref(false)
    const cargandoDatos = ref(false)
    const filtros = ref({
      id: -1,
      empleado: -1,
      cliente: -1,
      fecha: {
        desde: new Date(),
        hasta: new Date(),
      },
      grupo_negocio: -1,
    })
    const GetItem = item => {
      console.log(item)
      context.emit('GetItem', { ...item })
      goTo(0)
    }
    onBeforeMount(() => {
      cargarDatos()
    })
    const getFechas = datos => {
      console.log(datos)
      filtros.value.fecha.desde = datos.desde
      filtros.value.fecha.hasta = datos.hasta
    }
    const getEmpleado = dato => {
      if (dato != undefined) {
        filtros.value.empleado = dato.id
      } else {
        filtros.value.empleado = -1
      }
    }
    const getCliente = dato => {
      if (dato != undefined) {
        filtros.value.cliente = dato.id
      } else {
        filtros.value.cliente = -1
      }
    }
    const cargarDatosNuevo = () => {
      filtros.value = {
        id: -1,
        empleado: -1,
        cliente: -1,
        fecha: {
          desde: new Date(),
          hasta: new Date(),
        },
        grupo_negocio: -1,
      }

      cargarDatos()
    }
    const cargarDatos = () => {
      try {
        cargandoDatos.value = true
        ComandaService.comandaCuentaPorCobrarListar(filtros.value)
          .then(response => {
            console.log(response)
            if (response.data.mensaje == 'BUSQUEDA_EXITOSA') {
              Lista.value = response.data.datos
            }
          })
          .catch(error => {
            console.log(error)
          })
          .finally(() => {
            cargandoDatos.value = false
          })
      } catch (error) {
        store.commit('setAlert', {
          message: error,
          type: 'error', 
          error: error,
          funcion: 'cargarDatos',
        })
        cargandoDatos.value = false
      }
    }

    return {
      icons: {
        mdiMagnify,
        mdiDeleteOutline,
        mdiPencilOutline,
        mdiAccountCancel,
        mdiAccountEdit,
        mdiAccountCheck,
        mdiCheck,
        mdiCancel,
        mdiStoreEdit,
        mdiStoreEditOutline,
        mdiPencil,
        mdiCached,
        mdiEye,
      },
      search,
      headers: [
        { text: '#', value: 'id' },
        { text: 'GRUPO', value: 'grupo' },
        { text: 'CLIENTE', value: 'cliente' },
        { text: 'EMPLEADO', value: 'empleado' },
        { text: 'TOTAL', value: 'monto_total' },
        { text: 'ACCION', value: 'accion', sortable: false },
      ],
      Lista,
      ListaLoad,
      GetItem,
      cargarDatos,
      config,
      cargandoDatos,
      filtros,
      getFechas,
      getCliente,
      getEmpleado,
      FuncionesGenerales,
      cargarDatosNuevo,
    }
  },
}
</script>
  
<style lang="scss" scoped>
.table-kitchen-sink ::v-deep {
  .v-data-table-header {
    white-space: nowrap;
  }
}
</style>
  