<template>
  <div>
    <v-dialog v-model="dialog" width="700">
      <template v-slot:activator="{ on, attrs }">
        <v-btn @click="cargar_datos()" x-small text color="primary" dark v-bind="attrs" v-on="on"> Ver Log </v-btn>
      </template>

      <v-card>
        <v-card-title  > Log Comanda </v-card-title>

        <v-card-text>
          <v-timeline align-top dense>
            <v-timeline-item v-for="(item, index) in datos" :key="index" :color="colorAccion(item.id_accion)" small>
              <v-row class="pt-1">
                <v-col cols="3">
                  <small>{{ FuncionesGenerales.formatoFecha(item.fecha_hora, 1) }}</small> <br />
                  <strong>{{ FuncionesGenerales.formatoFecha(item.fecha_hora, 2) }}</strong> <br /><small
                    >{{ item.descripcion }}
                  </small>
                </v-col>
                <v-col>
                  <strong>{{ item.empleado }}</strong>
                  <div class="text-caption" v-for="(item2, index) in item.descripciones" :key="index">
                    {{ item2.detalle }}
                  </div>
                </v-col>
              </v-row>
            </v-timeline-item>
          </v-timeline>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" small @click="dialog = false"> Cerrar </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

  <script>
import { ref } from '@vue/composition-api'
import ComandaService from '@/api/servicios/ComandaService'

import FuncionesGenerales from '@/funciones/funciones'
export default {
  props: {
    id_comanda: Number,
  },
  setup(props) {
    const datos = ref([])
    const dialog = ref(false)
    const cargar_datos = () => {
      ComandaService.comandaLogListar({ id_comanda: props.id_comanda })
        .then(response => {
          datos.value = response.data.datos
          console.log(response.data)
        })
        .catch(error => {
          console.error(error)
          store.commit('setAlert', {
            message: error,
            type: 'error',
            error: {
                        ...error, 
                        response: error?.response?.data
                    },
            funcion: 'cargar_datos',
          })
        })
    }
    const colorAccion = accion => {
      switch (accion) {
        case 1:
          return 'success'
          break
        case 2:
          return 'info'
          break
        case 3:
          return 'error'
          break
        case 4:
          return 'warning'
          break
        case 5:
          return 'warning'
          break
        case 6:
          return 'error'
          break
        default:
          return 'info'
          break
      }
    }
    return {
      dialog,
      cargar_datos,
      datos,
      FuncionesGenerales,
      colorAccion,
    }
  },
}
</script>