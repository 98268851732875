<template>
  <v-row justify="center">
    <v-dialog v-model="dialog" persistent max-width="400">
      <v-card>
        <v-card-title class="text-h5"> Selecciona La impresora </v-card-title>
        <v-card-text>
          <v-row>
            <v-col cols="6" v-for="(item, index) in impresoras" :key="index">
              <v-card class="primary" @click="selectImpresora(item)">
                <v-card-text class="text-center">
                  <h4>
                    <span class="white--text">{{ item.nombre }}</span>
                  </h4>
                </v-card-text>
              </v-card>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="secondary" text @click="dialog = false"> Cerrar </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import { onBeforeMount, ref } from '@vue/composition-api'
import MaestroService from '@/api/servicios/MaestroService'
import { acciones } from '@/modulos'
import store from '@/store'

export default {
  setup(props, context) {
    
    const dialog = ref(false)
    const impresoras = ref([])
    const valorPadre = ref(null)
    const abrir = item => {
      valorPadre.value = item
      dialog.value = true
      consultar()
    }
    let funcion = null
    let valor = null

    const abrirFuncion = (_funcion, _valor) => {
      dialog.value = true
      funcion = _funcion
      valor = _valor
      consultar()
    }

    const validarAcciones = accion => {
      const valor = store.state.user.accion_modulo.includes(accion)

      return valor
    }


    const consultar = () => {
    
      MaestroService.obtenerGrupoImpresora()
        .then(response => {
          if (response.data.mensaje == 'BUSQUEDA_EXITOSA') {
             

            impresoras.value = []

            if ( validarAcciones(acciones.DASHBOARD_ADMINISTRACION_VER) ) {
                impresoras.value = response.data.datos
            }else{
                if (validarAcciones(acciones.DASHBOARD_COMANDA_IMPRIMIR)) {
                    impresoras.value = impresoras.value.concat(response.data.datos.filter(its => its.ind_mesoneros == true))  
                }
                if (validarAcciones(acciones.DASHBOARD_PUESTOS_COMANDA_VER)) {
                    impresoras.value = impresoras.value.concat(response.data.datos.filter(its => its.ind_puestos == true))  
                }
            }
          }
        })
        .catch(erro => {
          console.error(erro)
        })
    }

    const selectImpresora = item => {
      if (valor) {
        context.emit('getImpresoraFuncion', item.id, funcion, valor)
        dialog.value = false
      } else {
        context.emit('getImpresora', { ...valorPadre.value, id_grupo_impresora_pc: item.id })
        dialog.value = false
      }
    }

    return {
      dialog,
      consultar,
      abrir,
      selectImpresora,
      impresoras,
      abrirFuncion,
    }
  },
}
</script>