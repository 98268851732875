//import { axios } from '@/api';
import Axios from 'axios';
import store from '@/store'
import http from "../impresora";
import router from '@/router'


class ImpresoraService {
  
  imprimirFactura(datos) {
    return http.post(`/Fiscal/PrintInvoice?pDevolucion=${datos.pIsDevolucion}&pAbrirGaveta=${datos.pAbrirGaveta}&indIgtf=${datos.indIgtf}`, datos.factura)
    .catch((error) => { 
       if (error.response.status == 403) {
        store.commit("setUserData", { id: 0, userName: "", IsAutenticated: false });
        router.push('/pages/login').catch(error => {
        if (error.name != 'NavigationDuplicated') {
          throw error
        }
      });
      }else{
        throw error
      } 
    });
  } 
  imprimirNoFiscal(pLineas,impresora) {
    return http.post(`/Fiscal/PrintNoFiscal?pLineas=${pLineas}`, impresora)
    .catch((error) => { 
       if (error.response.status == 403) {
        store.commit("setUserData", { id: 0, userName: "", IsAutenticated: false });
        router.push('/pages/login').catch(error => {
        if (error.name != 'NavigationDuplicated') {
          throw error
        }
      });
      }else{
        throw error
      } 
    });
  }
  reporteZ(datos) {
    return http.post(`/Fiscal/PrintZ`, datos)
    .catch((error) => { 
       if (error.response.status == 403) {
        store.commit("setUserData", { id: 0, userName: "", IsAutenticated: false });
        router.push('/pages/login').catch(error => {
        if (error.name != 'NavigationDuplicated') {
          throw error
        }
      });
      }else{
        throw error
      } 
    });
  } 
  reporteZElectornica(datos) {
    return http.post(`/Fiscal/PrintZE`, datos)
    .catch((error) => { 
       if (error.response.status == 403) {
        store.commit("setUserData", { id: 0, userName: "", IsAutenticated: false });
        router.push('/pages/login').catch(error => {
        if (error.name != 'NavigationDuplicated') {
          throw error
        }
      });
      }else{
        throw error
      } 
    });
  } 
  reporteX(datos) {
    return http.post(`Fiscal/PrintX`, datos)
    .catch((error) => { 
       if (error.response.status == 403) {
        store.commit("setUserData", { id: 0, userName: "", IsAutenticated: false });
        router.push('/pages/login').catch(error => {
        if (error.name != 'NavigationDuplicated') {
          throw error
        }
      });
      }else{
        throw error
      } 
    });
  } 
  abrirGaveta(datos) {
    return http.post(`/Fiscal/OpenDrawer`, datos)
    .catch((error) => { 
       if (error.response.status == 403) {
        store.commit("setUserData", { id: 0, userName: "", IsAutenticated: false });
        router.push('/pages/login').catch(error => {
        if (error.name != 'NavigationDuplicated') {
          throw error
        }
      });
      }else{
        throw error
      } 
    });
  }  
  obtenerUltimoDocumento(datos) {
    return http.post(`/Fiscal/GetLastDocument?pDevolucion=${datos.pIsDevolucion}`, datos)
    .catch((error) => { 
       if (error.response.status == 403) {
        store.commit("setUserData", { id: 0, userName: "", IsAutenticated: false });
        router.push('/pages/login').catch(error => {
        if (error.name != 'NavigationDuplicated') {
          throw error
        }
      });
      }else{
        throw error
      } 
    });
  }  
  obtenerInfo(datos) {
    return http.post(`/Fiscal/GetInfo`, datos)
    .catch((error) => { 
      if (error.response.status == 403) {
        store.commit("setUserData", { id: 0, userName: "", IsAutenticated: false });
        router.push('/pages/login').catch(error => {
        if (error.name != 'NavigationDuplicated') {
          throw error
        }
      });
      }else{
        throw error
      } 
    });
  } 
  
  ImprimirConfiguracion(datos) {
    return http.post(`/Fiscal/PrintSettings`, datos)
    .catch((error) => { 
       if (error.response.status == 403) {
        store.commit("setUserData", { id: 0, userName: "", IsAutenticated: false });
        router.push('/pages/login').catch(error => {
        if (error.name != 'NavigationDuplicated') {
          throw error
        }
      });
      }else{
        throw error
      } 
    });
  } 
}

export default new ImpresoraService();