<template>
  <div>
    <v-dialog v-model="dialog" max-width="1000px" :hide-overlay="indFiscal" scrollable>
      <v-card>
        <v-card-text>
          <v-sheet id="scrolling-techniques-7" class="overflow-y-auto" v-if="!cargandoDatos && datos.id == undefined">
            <v-container>
              <v-card-text>
                <v-row>
                  <v-col cols="12">
                    <cabecera-empresa></cabecera-empresa>
                  </v-col>
                </v-row>
                <v-divider></v-divider>
                <h4 class="text-center">Ups hubo un problema al cargar los datos</h4>
              </v-card-text>
            </v-container>
          </v-sheet>
          <v-sheet id="scrolling-techniques-7" class="overflow-y-auto" v-else>
            <v-container>
              <v-card-text>
                <v-row>
                  <v-col lg="8" md="8" sm="12" cols="12">
                    <cabecera-empresa></cabecera-empresa>
                  </v-col>

                  <v-col lg="4" md="4" sm="12" cols="12">
                    <v-skeleton-loader v-if="cargandoDatos" type=" list-item-avatar,article"> </v-skeleton-loader>
                    <div v-if="!cargandoDatos">
                      <h2 class="pt-12 pb-2">Comanda # {{ datos.id }}</h2>
                      <span v-if="datos.doc_integracion">  Integracion: {{ datos.doc_integracion }} <br></span>
                      <span class="m-0 p-0">
                        Fecha creacion: {{ FuncionesGenerales.formatoFecha(datos.fecha, 3) }}</span
                      >
                      <br />
                      <ModalLogComandaVue :id_comanda="datos.id" />
                      <br />
                      <v-btn
                        :loading="fiscalizando || datos.cola_fiscal.estado == 27"
                        v-if="datos.estado_fiscalizado == 40 && indFiscal"
                        x-small
                        class="error mt-2"
                        @click="ClickFiscalizar(true)"
                      >
                        <v-icon left>{{ icons.mdiReceiptTextRemoveOutline }}</v-icon> Nota de Credito</v-btn
                      >
                      <v-btn
                        :loading="fiscalizando || datos.cola_fiscal.estado == 27"
                        v-if="(datos.estado_fiscalizado == 39 || datos.estado_fiscalizado == 44) && indFiscal"
                        x-small
                        class="success ml-2 mt-2"
                        @click="ClickFiscalizar(false)"
                      >
                        <v-icon left>{{ icons.mdiReceiptTextCheckOutline }}</v-icon> Facturar</v-btn
                      >

                      <v-btn
                        :loading="fiscalizando || datos.cola_fiscal.estado == 27"
                        v-if="(datos.estado_fiscalizado == 39 || datos.estado_fiscalizado == 44) && indFiscal"
                        x-small
                        class="success ml-2 mt-2"
                        @click="dialogGuardarFiscal = true"
                      >
                        <v-icon left>{{ icons.mdiReceiptTextCheckOutline }}</v-icon> Guardar Factura</v-btn
                      >
                    </div>
                  </v-col>
                </v-row>
              </v-card-text>
              <v-divider></v-divider>
              <v-card-text v-if="!cargandoDatos">
                <v-row>
                  <v-col lg="6" md="6" sm="12" cols="12">
                    <table>
                      <tr>
                        <td><span>Descripcion: </span>{{ datos.cliente }}</td>
                      </tr>
                      <tr>
                        <td>
                          <span>Cliente: </span>{{ FuncionesGenerales.camelCase(datos.nombre_cliente) }}
                          {{ FuncionesGenerales.camelCase(datos.apellido_cliente) }}
                          <v-btn
                            v-if="
                              ((datos.estado_fiscalizado == 39 || datos.estado_fiscalizado == 44) && indFiscal) ||
                              validarAcciones(acciones.DASHBOARD_ADMINISTRACION_ANULAR_COMANDA)
                            "
                            icon
                            color="primary"
                            @click="ModalClienteRef.CambiarEstadoDialogo(true)"
                          >
                            <v-icon>{{ icons.mdiCached }}</v-icon>
                          </v-btn>
                        </td>
                      </tr>
                      <tr>
                        <td><span>Mesa: </span>{{ FuncionesGenerales.camelCase(datos.mesa_nombre) }}</td>
                      </tr>

                      <tr>
                        <td>
                          <span>Estado Despacho: </span>{{ FuncionesGenerales.camelCase(datos.estado_despacho_nombre) }}
                        </td>
                      </tr>
                    </table>
                  </v-col>

                  <v-col lg="6" md="6" sm="12" cols="12">
                    <table>
                      <tr>
                        <td><span>Mesonero: </span>{{ FuncionesGenerales.camelCase(datos.mesonero_nombre) }}</td>
                      </tr>
                      <tr>
                        <td><span>Comandero: </span>{{ FuncionesGenerales.camelCase(datos.comandero_nombre) }}</td>
                      </tr>
                      <tr v-if="datos.cajero">
                        <td>
                          <span>Cajero: {{ FuncionesGenerales.camelCase(datos.cajero) }} </span>
                        </td>
                      </tr>
                      <tr>
                        <td><span>Estado pago: </span>{{ FuncionesGenerales.camelCase(datos.estado_pago_nombre) }}</td>
                      </tr>
                      <tr v-if="datos.estado_pago == 14">
                        <td>
                          <span>Anulado por: </span
                          >{{ FuncionesGenerales.camelCase(datos.anulacion_json.nombre_empleado) }}
                        </td>
                      </tr>
                      <tr v-if="datos.estado_pago == 14">
                        <td>
                          <span>Fecha anulacion: </span>
                          {{ FuncionesGenerales.formatoFecha(datos.anulacion_json.fecha, 3) }}
                        </td>
                      </tr>
                      <tr v-if="datos.estado_pago == 14">
                        <td>
                          <span>Motivo Anulacion: </span
                          >{{ FuncionesGenerales.camelCase(datos.anulacion_json.motivo, true) }}
                        </td>
                      </tr>
                      <tr>
                        <td v-if="datos.estado_pago == 7 && !datos.ind_exonerar_iva">
                          <v-btn @click="exonerarIva(datos)" x-small class="error mt-2">Exonerar Iva</v-btn>
                        </td>
                        <td v-if="datos.ind_exonerar_iva" class="error--text">Iva Exonerado</td>
                      </tr>
                    </table>
                  </v-col>
                </v-row>
              </v-card-text>
              <v-card-text>
                <v-skeleton-loader v-if="cargandoDatos" type="  list-item-avatar, list-item-avatar ">
                </v-skeleton-loader>

                <v-simple-table dense v-if="!cargandoDatos">
                  <template>
                    <thead>
                      <tr>
                        <th class="text-uppercase">Producto</th>
                        <th class="text-right text-uppercase">Cant/Pres</th>

                        <th class="text-right text-uppercase">SubTotal</th>
                        <th class="text-right text-uppercase">%Des</th>
                        <th class="text-right text-uppercase">IVA</th>

                        <th class="text-right text-uppercase">Total</th>
                        <th class="text-uppercase text-center">Estado</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr
                        v-for="(item, index) in datos.productos"
                        :key="index"
                        :class="item.estado == 14 && !item.ind_anulado_separa_unido ? 'error1' : ''"
                      >
                        <td class="">
                          <div class="d-flex align-center py-2">
                            <v-tooltip
                              bottom
                              v-if="
                                datos.estado_pago == 7 &&
                                !soloVer &&
                                validarAcciones(acciones.DASHBOARD_ADMINISTRACION_ANULAR_COMANDA_DETALLE)
                              "
                            >
                              <template v-slot:activator="{ on, attrs }">
                                <v-btn
                                  v-if="datos.estado_pago == 7"
                                  :loading="elimiandoItem == true"
                                  @click="
                                    eliminarItemComanda(
                                      {
                                        id_comanda: datos.id,
                                        id_comanda_detalle: item.id,
                                        id_empleado: store.state.user.id_empleado,
                                      },
                                      item,
                                    )
                                  "
                                  :disabled="item.estado == 14 || elimiandoItem == true"
                                  icon
                                  color="error"
                                  outlined
                                  x-small
                                  class="mr-2"
                                  v-bind="attrs"
                                  v-on="on"
                                >
                                  <v-icon>{{ icons.mdiClose }}</v-icon>
                                </v-btn>
                              </template>
                              <span>Anular Producto</span>
                            </v-tooltip>

                            <v-avatar
                              :color="item.producto.img ? '' : 'primary'"
                              :class="item.producto.img ? '' : 'v-avatar-light-bg primary--text'"
                              size="32"
                            >
                              <v-img
                                v-if="item.producto.img"
                                :src="`${config.api_imagenes}/assets/images/product/${item.producto.img}`"
                              >
                              </v-img>
                              <span v-else>{{ item.producto.nombre.slice(0, 2).toUpperCase() }}</span>
                            </v-avatar>
                            <div class="d-flex flex-column ms-3">
                              <span :class="' d-block font-weight-semibold   text-truncate'">{{
                                item.producto.nombre
                              }}</span>
                              <small>{{ item.producto.nombre_puesto }}</small>
                              <small v-if="item.estado == 14">Motivo: {{ item.anulacion_json.motivo }}</small>
                              <small v-if="item.estado == 14"
                                >{{
                                  !item.ind_anulado_separa_unido
                                    ? 'Anulada'
                                    : item.motivo_anulacion_detalle.includes('Union de comandas:')
                                    ? 'Unida'
                                    : 'Separada'
                                }}
                                Por: {{ item.anulacion_json.nombre_empleado }}</small
                              >
                              <small v-if="item.estado == 14">
                                {{ FuncionesGenerales.formatoFecha(item.anulacion_json.fecha, 3) }}</small
                              >
                            </div>
                          </div>
                        </td>
                        <td class="text-right">
                          <span> {{ item.cantidad.toFixed(2) }}</span>
                          <br />
                          $.{{ item.precio_real.toFixed(2) }}
                        </td>

                        <td class="text-right">
                          <span>{{ item.sub_total.toFixed(2) }}</span>
                        </td>

                        <td class="text-right">
                          <span>{{ item.por_descuento.toFixed(2) }}</span>
                        </td>
                        <td class="text-right">
                          <span>{{ item.iva.toFixed(2) }}</span>
                        </td>
                        <td class="text-right">
                          <span>{{ item.total.toFixed(2) }}</span>
                        </td>
                        <td class="text-center">
                          <v-chip v-if="item.estado == 14" class="ma-2" color="error" x-small>
                            {{
                              !item.ind_anulado_separa_unido
                                ? item.descripcion_estado
                                : item.motivo_anulacion_detalle.includes('Union de comandas:')
                                ? 'UNIDA'
                                : 'SEPARADA'
                            }}
                          </v-chip>
                          <v-chip v-else-if="item.estado == 13" class="ma-2" color="success" x-small>
                            {{ item.descripcion_estado }}
                          </v-chip>

                          <v-chip v-else-if="item.estado == 12" class="ma-2" color="warning" x-small>
                            {{ item.descripcion_estado }}
                          </v-chip>
                          <v-chip v-else class="ma-2" color="info" x-small>
                            {{ item.descripcion_estado }}
                          </v-chip>
                          <v-btn
                            x-small
                            v-if="item.estado == 14 && datos.estado_pago != 14"
                            outlined
                            color="error"
                            @click="ImprimirAnulacionItem(item)"
                            >Imprimir</v-btn
                          >
                        </td>
                      </tr>
                    </tbody>
                  </template>
                </v-simple-table>

                <v-divider></v-divider>
              </v-card-text>
              <v-card-text>
                <v-row>
                  <v-col cols="12" sm="12" md="6" lg="6">
                    <v-skeleton-loader v-if="cargandoDatos" type="  list-item-avatar, list-item-avatar">
                    </v-skeleton-loader>

                    <div v-if="datos.pagos && datos.pagos.length > 0 && !cargandoDatos">
                      <h5>Pagos</h5>
                      <v-simple-table dense v-if="!cargandoDatos">
                        <tbody>
                          <tr
                            v-for="(pago, index) in datos.pagos"
                            :key="index"
                            :class="pago.estado == 26 ? ' error1 white--text' : ''"
                          >
                            <td>
                              <v-simple-table dense>
                                <tbody>
                                  <tr :class="pago.estado == 26 ? ' error1 white--text' : ''">
                                    <td width="70%">
                                      <strong>Pago # {{ pago.id }}</strong>
                                    </td>
                                    <td width="30%" class="text-center">
                                      <span
                                        v-if="
                                          !soloVer &&
                                          validarAcciones(acciones.DASHBOARD_ADMINISTRACION_COMANDA_MODIFICAR_PAGO)
                                        "
                                      >
                                        <v-tooltip bottom v-if="pago.estado == 25">
                                          <template v-slot:activator="{ on, attrs }">
                                            <v-btn
                                              icon
                                              color="error"
                                              outlined
                                              x-small
                                              class="mr-2"
                                              :disabled="elimiandoPago"
                                              :loading="elimiandoPago"
                                              @click="
                                                eliminarPago(
                                                  {
                                                    id_comanda: datos.id,
                                                    id_pago: pago.id,
                                                    id_empleado: store.state.user.id_empleado,
                                                  },
                                                  datos,
                                                )
                                              "
                                              v-bind="attrs"
                                              v-on="on"
                                            >
                                              <v-icon>{{ icons.mdiClose }}</v-icon>
                                            </v-btn>
                                          </template>
                                          <span>Anular Pago</span>
                                        </v-tooltip>
                                      </span>

                                      <small v-if="pago.estado == 26">
                                        <v-chip class="ma-2" color="error" x-small> Anulado </v-chip>
                                      </small>
                                    </td>
                                  </tr>
                                  <tr
                                    v-for="(item, index) in pago.detalles"
                                    :key="index"
                                    :class="item.estado == 26 ? ' error1 white--text' : ''"
                                  >
                                    <td width="70%">
                                      <div class="d-flex align-center py-1">
                                        <v-avatar :class="'v-avatar-light-bg primary--text'" size="32">
                                          <span> {{ item.moneda.simbolo }} </span>
                                        </v-avatar>
                                        <div class="d-flex flex-column ms-3">
                                          <span :class="' d-block font-weight-semibold   text-truncate'">
                                            {{ item.moneda.tipo_pago[0].nombre }}
                                          </span>
                                          <small> Monto {{ item.moneda.simbolo }}: {{ item.monto_moneda }}</small>
                                          <small v-if="item.moneda.tasa != 1"
                                            >Monto $: {{ item.monto_real }}/ tasa: {{ item.moneda.tasa }}</small
                                          >
                                          <small v-if="item.referencia != ''"> Ref: {{ item.referencia }}</small>
                                        </div>
                                      </div>
                                    </td>
                                    <td width="30%" class="text-center">
                                      <span
                                        v-if="
                                          !soloVer &&
                                          validarAcciones(acciones.DASHBOARD_ADMINISTRACION_COMANDA_MODIFICAR_PAGO)
                                        "
                                      >
                                        <v-tooltip bottom v-if="item.estado == 25">
                                          <template v-slot:activator="{ on, attrs }">
                                            <v-btn
                                              icon
                                              color="info"
                                              outlined
                                              x-small
                                              class="mr-2"
                                              v-bind="attrs"
                                              v-on="on"
                                              @click="editarPago({ ...item, ind_propina: false })"
                                            >
                                              <v-icon>{{ icons.mdiPencilOutline }}</v-icon>
                                            </v-btn>
                                          </template>
                                          <span>Editar Pago</span>
                                        </v-tooltip>
                                      </span>
                                    </td>
                                  </tr>
                                </tbody>
                              </v-simple-table>
                            </td>
                          </tr>
                        </tbody>
                      </v-simple-table>

                      <br />
                    </div>
                    <br />
                    <v-skeleton-loader v-if="cargandoDatos" type="  list-item-avatar, list-item-avatar">
                    </v-skeleton-loader>

                    <div v-if="datos.propinas && datos.propinas.length > 0 && !cargandoDatos">
                      <h5>Propinas</h5>
                      <br />
                      <v-simple-table dense v-if="!cargandoDatos">
                        <template>
                          <tbody>
                            <tr
                              v-for="(item, index) in datos.propinas"
                              :key="index"
                              :class="item.estado == 6 ? ' error1 white--text' : ''"
                            >
                              <td width="70%">
                                <div class="d-flex align-center py-1">
                                  <v-avatar :class="'v-avatar-light-bg primary--text'" size="32">
                                    <span> {{ item.moneda.simbolo }} </span>
                                  </v-avatar>
                                  <div class="d-flex flex-column ms-3">
                                    <span :class="' d-block font-weight-semibold   text-truncate'">
                                      {{ item.moneda.tipo_pago[0].nombre }}
                                    </span>
                                    <small> Monto {{ item.moneda.simbolo }}: {{ item.monto_moneda }}</small>
                                    <small v-if="item.moneda.tasa != 1"
                                      >Monto $: {{ item.monto_real }}/ tasa: {{ item.moneda.tasa }}</small
                                    >
                                    <small v-if="item.referencia != ''"> Ref: {{ item.referencia }}</small>
                                    <small>
                                      {{ item.empleado_propina.nombre }} {{ item.empleado_propina.apellido }}</small
                                    >
                                  </div>
                                </div>
                              </td>
                              <td width="30%" class="text-center">
                                <v-tooltip
                                  bottom
                                  v-if="
                                    item.estado == 5 &&
                                    validarAcciones(acciones.DASHBOARD_ADMINISTRACION_COMANDA_MODIFICAR_PAGO) &&
                                    !soloVer
                                  "
                                >
                                  <template v-slot:activator="{ on, attrs }">
                                    <v-btn
                                      @click="
                                        editarPago({ ...item, pago_moneda: item.monto_moneda, ind_propina: true })
                                      "
                                      icon
                                      color="info"
                                      outlined
                                      x-small
                                      class="mr-2"
                                      v-bind="attrs"
                                      v-on="on"
                                    >
                                      <v-icon>{{ icons.mdiPencilOutline }}</v-icon>
                                    </v-btn>
                                  </template>
                                  <span>Editar Propina</span>
                                </v-tooltip>
                                <v-tooltip
                                  bottom
                                  v-if="
                                    item.estado == 5 &&
                                    !soloVer &&
                                    validarAcciones(acciones.DASHBOARD_ADMINISTRACION_COMANDA_MODIFICAR_PAGO)
                                  "
                                >
                                  <template v-slot:activator="{ on, attrs }">
                                    <v-btn
                                      icon
                                      color="error"
                                      outlined
                                      x-small
                                      class="mr-2"
                                      @click="
                                        eliminarPropina(
                                          {
                                            id_comanda: datos.id,
                                            id_propina: item.id,
                                            id_empleado: store.state.user.id_empleado,
                                          },
                                          datos,
                                        )
                                      "
                                      v-bind="attrs"
                                      v-on="on"
                                      :disabled="elimiandoPropina"
                                      :loading="elimiandoPropina"
                                    >
                                      <v-icon>{{ icons.mdiClose }}</v-icon>
                                    </v-btn>
                                  </template>
                                  <span>Anular Propina</span>
                                </v-tooltip>
                                <small v-if="item.estado == 6">
                                  <v-chip class="ma-2" color="error" x-small> Anulado </v-chip>
                                </small>
                              </td>
                            </tr>
                          </tbody>
                        </template>
                      </v-simple-table>
                    </div>
                  </v-col>
                  <v-divider vertical></v-divider>
                  <v-col cols="12" sm="12" md="6" lg="6">
                    <h5>Totales</h5>
                    <br />
                    <v-skeleton-loader
                      v-if="cargandoDatos"
                      type="  list-item-avatar, list-item-avatar, list-item-avatar"
                    >
                    </v-skeleton-loader>

                    <v-simple-table dense v-if="!cargandoDatos">
                      <template>
                        <tbody>
                          <tr>
                            <td>Sub Total</td>
                            <td class="text-right">$ {{ datos.subtotal.toFixed(2) }}</td>
                          </tr>
                          <tr>
                            <td>Descuento</td>
                            <td class="text-right">$ {{ datos.descuento.toFixed(2) }}</td>
                          </tr>
                          <tr>
                            <td>Servicio (10%)</td>
                            <td class="text-right">$ {{ datos.comision.toFixed(2) }}</td>
                          </tr>
                          <tr>
                            <td>IVA</td>
                            <td class="text-right">$ {{ datos.iva.toFixed(2) }}</td>
                          </tr>
                          <tr>
                            <td>IGTF</td>
                            <td class="text-right">$ {{ datos.igtf.toFixed(2) }}</td>
                          </tr>
                          <tr>
                            <td>
                              <h4><span class="primary--text">Total Comanda</span></h4>
                            </td>
                            <td class="text-right">
                              <h4>
                                <span class="primary--text">$ {{ (datos.total + datos.igtf).toFixed(2) }}</span>
                              </h4>
                            </td>
                          </tr>
                          <tr v-if="datos.total_propina > 0">
                            <td colspan="2"></td>
                          </tr>
                          <tr v-if="datos.total_propina > 0">
                            <td><span>Propina</span></td>
                            <td class="text-right">
                              <span>$ {{ datos.total_propina.toFixed(2) }}</span>
                            </td>
                          </tr>
                          <tr v-if="datos.total_propina > 0">
                            <td>
                              <h3><span class="success--text">Total Caja</span></h3>
                            </td>
                            <td class="text-right">
                              <h3>
                                <span class="success--text"
                                  >$ {{ (datos.total_propina + datos.total + datos.igtf).toFixed(2) }}</span
                                >
                              </h3>
                            </td>
                          </tr>
                        </tbody>
                      </template>
                    </v-simple-table>
                    <v-divider></v-divider>
                    <br />
                    <h5>Totales por puesto</h5>
                    <br />
                    <v-skeleton-loader
                      v-if="cargandoDatos"
                      type="  list-item-avatar, list-item-avatar, list-item-avatar"
                    >
                    </v-skeleton-loader>
                    <v-simple-table dark dense v-if="!cargandoDatos">
                      <template>
                        <thead>
                          <tr>
                            <th class="text-uppercase secondary">Puesto</th>
                            <th class="text-uppercase secondary">Sub</th>
                            <th class="text-uppercase secondary">Des</th>

                            <th class="text-uppercase secondary">Total</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr v-for="item in datos.totales_puestos" :key="item.id">
                            <td>{{ item.nombre }}</td>
                            <td>{{ item.sub_total }}</td>
                            <td>{{ item.por_descuento }}</td>
                            <td>{{ item.total }}</td>
                          </tr>
                        </tbody>
                      </template>
                    </v-simple-table>
                  </v-col>
                </v-row>
              </v-card-text>
            </v-container>
          </v-sheet>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn small class="primary" @click="dialogTipoPrint = true">PDF</v-btn>
          <v-btn color="secondary" small @click="dialog = false"> Cerrar </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <ImpresoraSelect ref="ImpresoraSelectRef" @getImpresora="getImpresora" />
    <ModalCliente ref="ModalClienteRef" @GetClienteFull="GetClienteFull" />
    <ModalCambiarFormaPago ref="ModalCambiarFormaPagoRef" @actualizar="CargarDatos" />
    <PdfFormatoLibre ref="PdfFormatoLibreRef"></PdfFormatoLibre>

    <v-dialog v-model="dialogTipoPrint" width="500">
      <v-card>
        <v-card-title class="text-h5 grey lighten-2"> Tipo de documento </v-card-title>

        <v-card-text>
          <v-item-group mandatory v-model="verMoneda">
            <v-container>
              <v-row>
                <v-col>
                  <v-item v-slot="{ active, toggle }">
                    <v-card
                      dark
                      :color="active ? 'primary' : ''"
                      class="d-flex align-center"
                      height="50"
                      @click="toggle"
                    >
                      <v-scroll-y-transition>
                        <div class="white--text flex-grow-1 text-center">Moneda Principal</div>
                      </v-scroll-y-transition>
                    </v-card>
                  </v-item>
                </v-col>

                <v-col>
                  <v-item v-slot="{ active, toggle }">
                    <v-card
                      dark
                      :color="active ? 'primary' : ''"
                      class="d-flex align-center"
                      height="50"
                      @click="toggle"
                    >
                      <v-scroll-y-transition>
                        <div class="white--text flex-grow-1 text-center">Moneda Pais</div>
                      </v-scroll-y-transition>
                    </v-card>
                  </v-item>
                </v-col>
              </v-row>
            </v-container>
          </v-item-group>

          <v-row>
            <v-col>
              <v-card class="d-flex align-center primary" height="100" @click="generarPDF(4)">
                <v-scroll-y-transition>
                  <div class="flex-grow-1 text-center white--text">Presupuesto</div>
                </v-scroll-y-transition>
              </v-card>
            </v-col>
            <v-col>
              <v-card class="d-flex align-center primary" height="100" @click="generarPDF(1)">
                <v-scroll-y-transition>
                  <div class="flex-grow-1 text-center white--text">Nota de Entrega</div>
                </v-scroll-y-transition>
              </v-card>
            </v-col>
            <v-col v-if="datos.total_por_cobrar == 0 && datos.estado_pago == 9">
              <v-card class="d-flex align-center primary" height="100" @click="generarPDF(2)">
                <v-scroll-y-transition>
                  <div class="flex-grow-1 text-center white--text">Recibo de pago</div>
                </v-scroll-y-transition>
              </v-card>
            </v-col>
            <v-col v-if="datos.total_por_cobrar > 0">
              <v-card class="d-flex align-center primary" height="100" @click="generarPDF(3)">
                <v-scroll-y-transition>
                  <div class="flex-grow-1 text-center white--text">Orden de cobro</div>
                </v-scroll-y-transition>
              </v-card>
            </v-col>
          </v-row>

          <v-divider></v-divider>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="secondary" small @click="dialogTipoPrint = false"> Cerrar </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="dialogGuardarFiscal" width="500">
      <v-card>
        <v-card-title class="text-h5 grey lighten-2"> Guardar Factura </v-card-title>

        <v-card-text>
          <v-row>
            <v-col cols="12" sm="6">
              <v-text-field v-model="_numero_control" outlined dense label="Numero Factura"></v-text-field>
            </v-col>
            <v-col cols="12" sm="6">
              <v-text-field v-model="_serial_fiscal" outlined dense label="Serial Impresora"></v-text-field>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn small class="primary" @click="ClickGuardarFiscalizar()">GUARDAR</v-btn>
          <v-btn color="secondary" small @click="dialogGuardarFiscal = false"> Cerrar </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import ComandaService from '@/api/servicios/ComandaService'
import { onBeforeMount, ref, watch } from '@vue/composition-api'
import { mdiClose, mdiPencilOutline, mdiCached } from '@mdi/js'
import config from '@/api/config'
import store from '@/store'
import FuncionesGenerales from '@/funciones/funciones'
import Vue from 'vue'
import CabeceraEmpresa from '@/components/CabeceraEmpresa.vue'
import { acciones } from '@/modulos'
import ImpresoraSelect from '@/views/sistema/comanda/dashboard/componentes/ImpresoraSelect.vue'
import Fiscalizar from '@/funciones/fiscalizar'
import ModalCliente from '@/views/sistema/comanda/dashboard/componentes/ModalCliente.vue'

import ModalLogComandaVue from './ModalLogComanda.vue'
import ModalCambiarFormaPago from './ModalCambiarFormaPago.vue'
import PdfFormatoLibre from '../../../../../components/PdfFormatoLibreDocumentos.vue'
import { socket, state as stateSocket } from '@/plugins/sockets'
import moment from 'moment'
export default {
  components: {
    CabeceraEmpresa,
    ImpresoraSelect,
    ModalCliente,
    ModalLogComandaVue,
    ModalCambiarFormaPago,
    PdfFormatoLibre,
  },

  props: {
    comanda: Object,
    openModal: Boolean,
    soloVer: Boolean,
    indFiscal: {
      type: Boolean,
      default: false,
    },
    serialFiscal: {
      type: String,
      default: '',
    },
    monedas: {
      type: Array,
      default: null,
    },
  },
   
  setup(props, context) {
    socket.on('modificar-comanda', (...args) => {
        if (args){
          if (args[0].id_comanda == props.comanda.id){
        CargarDatos()
       }
        }
      
    })

    const dialogTipoPrint = ref(false)
    const mdiReceiptTextRemoveOutline =
      'M21.12 15.46L19 17.59L16.88 15.47L15.47 16.88L17.59 19L15.47 21.12L16.88 22.54L19 20.41L21.12 22.54L22.54 21.12L20.41 19L22.54 16.88L21.12 15.46M13.26 20.74L12 22L10.5 20.5L9 22L7.5 20.5L6 22L4.5 20.5L3 22V2L4.5 3.5L6 2L7.5 3.5L9 2L10.5 3.5L12 2L13.5 3.5L15 2L16.5 3.5L18 2L19.5 3.5L21 2V13.35C20.37 13.13 19.7 13 19 13V5H5V19H13C13 19.57 13.1 20.22 13.26 20.74M14.54 15C14 15.58 13.61 16.25 13.35 17H6V15H14.54M6 11H18V13H6V11M6 7H18V9H6V7Z'
    const mdiReceiptTextCheckOutline =
      'M13.26 20.74L12 22L10.5 20.5L9 22L7.5 20.5L6 22L4.5 20.5L3 22V2L4.5 3.5L6 2L7.5 3.5L9 2L10.5 3.5L12 2L13.5 3.5L15 2L16.5 3.5L18 2L19.5 3.5L21 2V13.35C20.37 13.13 19.7 13 19 13V5H5V19H13C13 19.57 13.1 20.22 13.26 20.74M6 15V17H13.35C13.61 16.25 14 15.58 14.54 15H6M6 13H18V11H6V13M6 9H18V7H6V9M23 17.23L21.84 15.82L18.25 19.41L16.66 17.82L15.5 19L18.25 22'
    const ModalClienteRef = ref()
    const dialog = ref(false)
    const datos = ref({})
    const elimiandoItem = ref(false)
    const ImpresoraSelectRef = ref(null)
    const ModalCambiarFormaPagoRef = ref(null)
    const PdfFormatoLibreRef = ref(null)
    const validarAcciones = accion => {
      const valor = store.state.user.accion_modulo.includes(accion)

      return valor
    }
    const verMoneda = ref(0)
    const monedaSimbolo = () => {
      let simbolo = '$'
      if (verMoneda.value == 1) {
        console.log(MonedaPais)
        simbolo = MonedaPais.simbolo
      }
      return simbolo
    }
    const monedaValor = _dato => {
      let valor = _dato
      if (verMoneda.value == 1) {
        valor = valor * datos.value.tasa_modeda_pais
      }
      return valor
    }
    const generarPDF = (_tipo = 1) => {
      const datoInit = datos.value
      const cuerpoTablaDetalles = []
      const FormasPagos = [{ texto: 'Formas pago', valor: '' }]

      datoInit.productos.forEach(item => {
        if (item.estado != 14) {
          cuerpoTablaDetalles.push([
            item.producto.id.toString(),
            item.producto.nombre,
            FuncionesGenerales.formatoNumeric(item.cant),
            monedaSimbolo() + ' ' + FuncionesGenerales.formatoNumeric(monedaValor(item.precio_real)),
            monedaSimbolo() + ' ' + FuncionesGenerales.formatoNumeric(monedaValor(item.descuento)),
            monedaSimbolo() + ' ' + FuncionesGenerales.formatoNumeric(monedaValor(item.iva)),
            monedaSimbolo() + ' ' + FuncionesGenerales.formatoNumeric(monedaValor(item.total + item.iva)),
          ])
        }
      })

      datoInit.pagos.forEach(pago => {
        pago.detalles.forEach(det => {
          FormasPagos.push({
            texto: '',
            valor: `${det.moneda.nombre}  ${det.moneda.tipo_pago[0].nombre} : ${det.monto_moneda} ${det.moneda.simbolo}`,
          })
        })
      })
      const datosInit = {
        cabecera1: { visible: true },
        nombreDocumento: `${datoInit.id.toString()}`,

        numeroDocumento: {
          texto: 'Orden:',
          valor: datoInit.id.toString(),
        },
        numeroControl: {
          texto: 'Doc Nro Com-',
          valor: datoInit.id.toString(),
        },
        numeroFecha: {
          texto: 'Fecha',
          valor: moment(datoInit.fecha).format('YYYY-MM-DD'),
        },
        sub1: {
          linea1: [
            { texto: 'Cliente:', valor: datoInit.nombre_cliente + ' ' + datoInit.apellido_cliente },
            { texto: 'Rif:', valor: datoInit.documento },
            { texto: 'Telefono:', valor: datoInit.telefono_cliente ? datoInit.telefono_cliente : '' },
            { texto: 'Direccion:', valor: datoInit.direccion_cliente ? datoInit.direccion_cliente : '' },
          ],
          linea2: [],
        },
        sub2: {
          linea1: [],
          linea2: [],
        },

        pie: {
          nota: {
            texto: 'Descripcion:',
            valor: datoInit.cliente,
          },
          totales: [
            {
              texto: 'SubTotal:',
              valor: monedaSimbolo() + ' ' + FuncionesGenerales.formatoNumeric(monedaValor(datoInit.subtotal)),
            },
            {
              texto: 'Descuento:',
              valor: monedaSimbolo() + ' ' + FuncionesGenerales.formatoNumeric(monedaValor(datoInit.descuento)),
            },
            {
              texto: 'Impuesto:',
              valor: monedaSimbolo() + ' ' + FuncionesGenerales.formatoNumeric(monedaValor(datoInit.iva)),
            },
            {
              texto: 'IGTF:',
              valor: monedaSimbolo() + ' ' + FuncionesGenerales.formatoNumeric(monedaValor(datoInit.igtf)),
            },
            {
              texto: 'Total:',
              valor:
                monedaSimbolo() + ' ' + FuncionesGenerales.formatoNumeric(monedaValor(datoInit.total + datoInit.igtf)),
            },
          ],
        },

        detalles: {
          cabecera: [
            { texto: 'Id', posicion: '45', alineacion: 'left' },
            {
              texto: 'Producto',
              posicion: '80',
              alineacion: 'left',
              justificar: { maxWidth: 130, align: 'justify', maximoItems: 33 },
            },
            { texto: 'Cant', posicion: '240', alineacion: 'right' },
            { texto: 'PVPUnd', posicion: '300', alineacion: 'right' },
            { texto: 'Des', posicion: '400', alineacion: 'right' },
            { texto: 'IVA', posicion: '460', alineacion: 'right' },
            { texto: 'Total', posicion: '555', alineacion: 'right' },
          ],
          cuerpo: cuerpoTablaDetalles,
        },
      }

      if (_tipo == 1) {
        datosInit.nombreDocumento = 'Nota de entrega:' + datosInit.nombreDocumento
        datosInit.numeroDocumento.texto = 'Nota de Entrega:'
        datosInit.numeroControl.texto = 'DOC-NE-'
      } else if (_tipo == 2) {
        datosInit.nombreDocumento = 'Recibo:' + datosInit.nombreDocumento
        datosInit.numeroDocumento.texto = 'Recibo:'
        datosInit.numeroControl.texto = 'DOC-RE-'
        datosInit.sub2.linea1 = FormasPagos
      } else if (_tipo == 3) {
        datosInit.nombreDocumento = 'Orden de cobro:' + datosInit.nombreDocumento
        datosInit.numeroDocumento.texto = 'Orden de cobro:'
        datosInit.numeroControl.texto = 'DOC-OC-'
        datosInit.sub2.linea1 = [{ texto: 'Por Cobrar:', valor: datoInit.total_por_cobrar.toString() }]
      } else if (_tipo == 4) {
        datosInit.nombreDocumento = 'Presupuesto Nro:' + datosInit.nombreDocumento
        datosInit.numeroDocumento.texto = 'Presupuesto Nro:'
        datosInit.numeroControl.texto = 'DOC-PRE-'
      }

      PdfFormatoLibreRef.value.generarPDF(datosInit)
    }

    const GetClienteFull = cliente => {
      ComandaService.cambiarClienteComanda({ id_comanda: datos.value.id, id_cliente: cliente.id })
        .then(response => {
          if (response.data.mensaje == 'CLIENTE ASIGNADO CON EXITO') {
            CargarDatos()
          } else {
            store.commit('setAlert', {
              message: response.data.mensaje,
              type: 'info',
            })
          }
        })
        .catch(error => {
          store.commit('setAlert', {
            message: error,
            type: 'error',
            error: {
              ...error,
              response: error?.response?.data,
            },
            funcion: 'GetClienteFull',
          })
        })
    }
    onBeforeMount(() => {
      dialog.value = props.openModal
    })
    let MonedaPais = props.monedas?.filter(item => item.meneda_pais == true)[0]
    watch(props, () => {
      dialog.value = props.openModal
      MonedaPais = props.monedas?.filter(item => item.meneda_pais == true)[0]
    })

    watch(dialog, () => {
      if (dialog.value == false) {
        context.emit('GetOpenModal', dialog.value)
      } else {
        CargarDatos()
      }
    })

    const ImprimirAnulacionItem = item => {
      ImpresoraSelectRef.value.abrir(item)
    }
    const getImpresora = item => {
      ImprimirAnulacionItemDatos(item)
    }
    const ImprimirAnulacionItemDatos = item => {
      item.imprimiendo = true
      ComandaService.ImprimirAnulacion({
        id_comanda: item.id_comanda,
        id_comanda_detalle: item.id,
        id_grupo_impresora_pc: item.id_grupo_impresora_pc,
      })
        .then(response => {
          if (response.data.mensaje == 'guardado con exito') {
            store.commit('setAlert', {
              message: 'Se envio a la impresora',
              type: 'success',
            })
          } else {
            store.commit('setAlert', {
              message: response.data.mensaje,
              type: 'info',
            })
          }
        })
        .catch(error => {
          store.commit('setAlert', {
            message: 'Error ' + error,
            type: 'error',

            error: {
              ...error,
              response: error?.response?.data,
            },
            funcion: 'ImprimirAnulacionItemDatos',
          })
        })
        .finally(() => {
          item.imprimiendo = false
        })
    }
    const fiscalizando = ref(false)
    const ClickFiscalizar = async devolucion => {
      fiscalizando.value = true
      await Fiscalizar.Fiscalizar({
        item: datos.value,
        _MonedaPais: { ...MonedaPais, tasa: datos.value.tasa_modeda_pais },
        _serialFiscal: props.serialFiscal,
        _ind_devolucion: devolucion,
        _imprimir_tikera: true,
      })
      CargarDatos()
      fiscalizando.value = false
    }

    const dialogGuardarFiscal = ref(false)

    const abrirGuardarFacturaFiscal = () => {
      dialogGuardarFiscal.value = true
      _serial_fiscal.value = props.serialFiscal
      _numero_control.value = ''
    }

    const _numero_control = ref('')
    const _serial_fiscal = ref('')

    const ClickGuardarFiscalizar = async devolucion => {
      fiscalizando.value = true
      await Fiscalizar.Fiscalizar({
        item: { ...datos.value, numero_control: _numero_control.value },
        _MonedaPais: { ...MonedaPais, tasa: datos.value.tasa_modeda_pais },
        _serialFiscal: _serial_fiscal.value,
        _ind_devolucion: devolucion,
        _imprimir_tikera: false,
      })
      CargarDatos()
      fiscalizando.value = false
    }

    const editarPago = item => {
      ModalCambiarFormaPagoRef.value.abrir({ ...item, id_comanda: props.comanda.id })
    }

    const exonerando = ref(false)
    const exonerarIva = item => {
      const datos = {
        id_comanda: item.id,
        id_empleado: store.state.user.id_empleado,
        ind_exonerar_iva: true,
      }
      exonerando.value = true
      Vue.swal({
        icon: 'warning',
        html:
          '<h3>¿Seguro que quiere exonerar del IVA la comanda? </h3>' +
          `<p># ${item.id}</p>
                    <p>Al exonerar la comanda no se podra fiscalizar esta comanda</p>
                    `,
        showCancelButton: true,
        confirmButtonText: 'Si, Seguro',
        showLoaderOnConfirm: true,
        cancelButtonText: 'No',
        preConfirm: () => {
          return ComandaService.exonerarIvaComanda(datos)
            .then(response => {
              if (response.data.mensaje == 'MODIFICADA_CON_EXITO') {
                Vue.swal({
                  icon: 'success',
                  title: `Exito`,
                  text: response.data.mensaje,
                })
              } else {
                Vue.swal({
                  icon: 'error',
                  title: `Oops...`,
                  text: response.data.mensaje,
                })
                exonerando.value = false
              }
              return true
            })
            .catch(error => {
              Vue.swal({
                icon: 'error',
                title: `Oops...`,
                text: `hubo un error: ${error}`,
              })
              exonerando.value = false
              return false
            })
        },
        allowOutsideClick: () => !Swal.isLoading(),
      }).then(result => {
        /*  
                   if (result.isConfirmed) {
                       elimiandoPago.value = false
                   } else if (result.dismiss === "cancel") {
                       elimiandoPago.value = false
                   }
                 */
        dialog.value = false
        exonerando.value = false
      })
    }

    const elimiandoPropina = ref(false)
    const eliminarPropina = (datos, item) => {
      elimiandoPropina.value = true
      Vue.swal({
        icon: 'warning',
        html: '<h3>¿Seguro que quiere eliminar la propina de la comanda? </h3>' + `<p># ${item.id}</p>`,
        showCancelButton: true,
        confirmButtonText: 'Si, Seguro',
        showLoaderOnConfirm: true,
        cancelButtonText: 'No',
        preConfirm: () => {
          return ComandaService.eliminarPropinaComandaAdmin(datos)
            .then(response => {
              if (response.data.mensaje == 'COMANDA MODIFICADA CON EXITO') {
                Vue.swal({
                  icon: 'success',
                  title: `Exito`,
                  text: response.data.mensaje,
                })
                CargarDatos()
              } else {
                Vue.swal({
                  icon: 'error',
                  title: `Oops...`,
                  text: response.data.mensaje,
                })
                elimiandoPropina.value = false
              }
              return true
            })
            .catch(error => {
              Vue.swal({
                icon: 'error',
                title: `Oops...`,
                text: `hubo un error: ${error}`,
              })
              elimiandoPropina.value = false
              return false
            })
        },
        allowOutsideClick: () => !Swal.isLoading(),
      }).then(result => {
        /*  
                   if (result.isConfirmed) {
                       elimiandoPago.value = false
                   } else if (result.dismiss === "cancel") {
                       elimiandoPago.value = false
                   }
                 */
        elimiandoPropina.value = false
      })
    }

    const elimiandoPago = ref(false)
    const eliminarPago = (datos, item) => {
      elimiandoPago.value = true
      Vue.swal({
        icon: 'warning',
        html:
          '<h3>¿Seguro que quiere eliminar el pago de la comanda? </h3>' +
          `<p># ${item.id}</p>` +
          `<p style="color:red;">¡Importante!</p>` +
          `<p style="color:red;"  ><small>Al eliminar los pagos tambien se eliminaran las propinas asociadas a la comanda</small></p>`,
        showCancelButton: true,
        confirmButtonText: 'Si, Seguro',
        showLoaderOnConfirm: true,
        cancelButtonText: 'No',
        preConfirm: () => {
          return ComandaService.eliminarPagoComandaAdmin(datos)
            .then(response => {
              if (response.data.mensaje == 'COMANDA MODIFICADA CON EXITO') {
                Vue.swal({
                  icon: 'success',
                  title: `Exito`,
                  text: response.data.mensaje,
                })
                CargarDatos()
              } else {
                Vue.swal({
                  icon: 'error',
                  title: `Oops...`,
                  text: response.data.mensaje,
                })
                elimiandoPago.value = false
              }
              return true
            })
            .catch(error => {
              Vue.swal({
                icon: 'error',
                title: `Oops...`,
                text: `hubo un error: ${error}`,
              })
              elimiandoPago.value = false
              return false
            })
        },
        allowOutsideClick: () => !Swal.isLoading(),
      }).then(result => {
        /*  
                   if (result.isConfirmed) {
                       elimiandoPago.value = false
                   } else if (result.dismiss === "cancel") {
                       elimiandoPago.value = false
                   }
                 */
        elimiandoPago.value = false
      })
    }

    const eliminarItemComanda = (datos, item) => {
      dialog.value = false
      elimiandoItem.value = true
      Vue.swal({
        html:
          `<img style="border-radius: 50px;" src="${config.api_imagenes}/producto/img/m-${item.producto.img}" height="100px" max-width="100px"/> <br>` +
          '<h3>¿Seguro que quiere anular? </h3>' +
          `<p>(${item.cantidad}) ${item.producto.nombre}</p>` +
          `<p><small>${item.producto.nombre_puesto}</small></p>
                    <textarea id="swal-motivo-anulacion3"      class="swal2-textarea" placeholder="Motivo anulación"></textarea>  `,

        showCancelButton: true,
        confirmButtonText: 'Si, Seguro',
        showLoaderOnConfirm: true,
        cancelButtonText: 'No',
        preConfirm: () => {
          const motivo = document.getElementById('swal-motivo-anulacion3').value
          console.log(motivo)

          if (motivo != undefined && motivo != '' && motivo != null) {
            return ComandaService.eliminarItemComandaAdmin({ ...datos, motivo })
              .then(response => {
                if (response.data.mensaje == 'COMANDA MODIFICADA CON EXITO') {
                  Vue.swal({
                    icon: 'success',
                    title: `Exito`,
                    text: response.data.mensaje,
                  })
                  CargarDatos()
                } else {
                  Vue.swal({
                    icon: 'error',
                    title: `Oops...`,
                    text: response.data.mensaje,
                  })

                  elimiandoItem.value = false
                }
                return true
              })
              .catch(error => {
                Vue.swal({
                  icon: 'error',
                  title: `Oops...`,
                  text: `hubo un error: ${error}`,
                })

                elimiandoItem.value = false
                return false
              })
          } else {
            Vue.swal.showValidationMessage(`Debe proporcionar un motivo de anulacion`)
          }
        },
        allowOutsideClick: () => {
          if (!Vue.swal.isLoading()) {
            Vue.swal.close()
            elimiandoItem.value = false
          }
        },
      }).then(result => {
        if (result.isConfirmed) {
          elimiandoItem.value = false
        } else if (result.dismiss === 'cancel') {
          elimiandoItem.value = false
        }
        dialog.value = true
      })
    }

    const cargandoDatos = ref(false)
    const CargarDatos = () => {
      datos.value = {}
      cargandoDatos.value = true
      ComandaService.listarComandaAdmin({ id: props.comanda.id })
        .then(response => {
          datos.value = response.data.datos
        })
        .catch(error => {
          store.commit('setAlert', {
            message: 'Ups, hubo un error: ' + error,
            type: 'error',

            error: {
              ...error,
              response: error?.response?.data,
            },
            funcion: 'CargarDatos',
          })
        })
        .finally(() => {
          cargandoDatos.value = false
        })
    }

    return {
      dialog,
      datos,
      icons: {
        mdiClose,
        mdiPencilOutline,
        mdiReceiptTextRemoveOutline,
        mdiReceiptTextCheckOutline,
        mdiCached,
      },
      config,
      cargandoDatos,
      store,
      elimiandoItem,
      eliminarItemComanda,
      elimiandoPago,
      eliminarPago,
      elimiandoPropina,
      eliminarPropina,
      FuncionesGenerales,
      validarAcciones,
      acciones,
      exonerarIva,
      ImprimirAnulacionItem,
      getImpresora,
      ImpresoraSelectRef,
      Fiscalizar,
      ClickFiscalizar,
      fiscalizando,
      ModalClienteRef,
      GetClienteFull,
      ModalCambiarFormaPagoRef,
      editarPago,
      CargarDatos,
      PdfFormatoLibreRef,
      generarPDF,
      dialogTipoPrint,
      monedaValor,
      monedaSimbolo,
      verMoneda,
      dialogGuardarFiscal,
      abrirGuardarFacturaFiscal,
      _serial_fiscal,
      _numero_control,
      ClickGuardarFiscalizar,
    }
  },
}
</script>