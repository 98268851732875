var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-card',[_c('v-card-title',[_vm._v("Lista pagos "),_c('v-btn',{attrs:{"icon":"","color":"green","loading":_vm.cargandoDatos,"disabled":_vm.cargandoDatos},on:{"click":function($event){return _vm.cargarDatos()}}},[_c('v-icon',[_vm._v(_vm._s(_vm.icons.mdiCached))])],1)],1),_c('v-card-text',[_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"4"}},[_c('dos-fechas',{attrs:{"fechas":[
              _vm.FuncionesGenerales.formatoFecha(_vm.filtros.fecha.desde, 4),
              _vm.FuncionesGenerales.formatoFecha(_vm.filtros.fecha.hasta, 4) ],"icon":true},on:{"getFechas":_vm.getFechas}})],1),_c('v-col',{attrs:{"cols":"12","md":"4"}},[_c('buscar-cliente',{attrs:{"icon":true},on:{"Getcliente":_vm.getCliente}})],1),_c('v-col',{attrs:{"cols":"10","md":"3"}},[_c('buscar-empleado',{attrs:{"icon":true},on:{"Getdatos":_vm.getEmpleado}})],1),_c('v-col',{attrs:{"cols":"2","md":"1"}},[_c('v-btn',{attrs:{"icon":"","color":"primary","loading":_vm.cargandoDatos,"disabled":_vm.cargandoDatos},on:{"click":function($event){return _vm.cargarDatos()}}},[_c('v-icon',[_vm._v(_vm._s(_vm.icons.mdiMagnify))])],1)],1)],1),_c('v-row',[(_vm.Lista.length > 0)?_c('v-col',{attrs:{"cols":"12"}},[_c('v-text-field',{attrs:{"append-icon":_vm.icons.mdiMagnify,"label":"Filtrar","single-line":"","hide-details":"","dense":"","outlined":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1):_vm._e()],1)],1),_c('v-data-table',{staticClass:"table-kitchen-sink",attrs:{"headers":_vm.headers,"items":_vm.Lista,"search":_vm.search,"items-per-page":5,"loading":_vm.ListaLoad},scopedSlots:_vm._u([{key:"item.id",fn:function(ref){
            var item = ref.item;
return [_c('strong',{staticClass:"text-no-wrap"},[_vm._v("#"+_vm._s(item.id)+" ")]),_c('br'),_c('small',{staticClass:"text-no-wrap"},[_vm._v(_vm._s(_vm.FuncionesGenerales.formatoFecha(item.fecha, 3)))])]}},{key:"item.grupo",fn:function(ref){
            var item = ref.item;
return [_vm._v(" "+_vm._s(item.grupo_negocio_json.descripcion)+" ")]}},{key:"item.cliente",fn:function(ref){
            var item = ref.item;
return [_vm._v(" "+_vm._s(item.cliente_json.nombre)+" "+_vm._s(item.cliente_json.apellido)+" ")]}},{key:"item.empleado",fn:function(ref){
            var item = ref.item;
return [_vm._v(" "+_vm._s(item.empleado_json.nombre)+" "+_vm._s(item.empleado_json.apellido)+" ")]}},{key:"item.monto_total",fn:function(ref){
            var item = ref.item;
return [_vm._v(" $"+_vm._s(item.monto_total)+" ")]}},{key:"item.accion",fn:function(ref){
            var item = ref.item;
return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
            var on = ref.on;
            var attrs = ref.attrs;
return [_c('a',_vm._g(_vm._b({staticClass:"pr-2",on:{"click":function($event){return _vm.GetItem(item)}}},'a',attrs,false),on),[_c('v-icon',[_vm._v(_vm._s(_vm.icons.mdiEye))])],1)]}}],null,true)},[_c('span',[_vm._v("Ver")])])]}}],null,true)})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }