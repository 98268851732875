


import ImpresoraService from '@/api/servicios/ImpresoraService'
import ComandaService from '@/api/servicios/ComandaService'
import store from '@/store'
import Vue from 'vue'
import funciones from './funciones'
import jsPDF from 'jspdf'
import moment from 'moment'
"use strict";

class Fiscalizar {

    cargando = false
    MonedaPais = {}
    configuracion = {
        igtf: 3
    }

    round = num => {
        var m = Number((Math.abs(num) * 100).toPrecision(15))
        return (Math.round(m) / 100) * Math.sign(num)
    }
    imprimirDocumentoNoFiscal = async (pLineas, impresora) => {
        await ImpresoraService.imprimirNoFiscal(pLineas, impresora)
            .then(response => {
                store.commit('setAlert', {
                    message: `Impresion tiket no fiscal`,
                    type: 'success',
                })
            })
            .catch(error => {
                store.commit('setAlert', {
                    message: `error Impresion tiket no fiscal`,
                    type: 'error',
                    error: {
                        ...error,
                        response: error?.response?.data
                    },
                    funcion: 'imprimirDocumentoNoFiscal',
                })
            })
    }


    errorFiscal = async (facturas, ind_devolucion = false) => {



        await Vue.swal({
            icon: 'warning',
            html:
                '<h3>¿Hubo un error, la factura se emitio? </h3>' +
                ` ` +
                ` 
                    <input style="    id="swal-motivo-anulacion"   class="swal2-input" placeholder="Numero ${ind_devolucion ? 'Factura' : 'Nota de Credito'}">  `,
            showCancelButton: true,
            confirmButtonText: 'Si, Seguro',
            showLoaderOnConfirm: true,
            cancelButtonText: 'No',
            preConfirm: async () => {

                try {
                    const numero = document.getElementById('swal-motivo-anulacion').value
                    if (numero != undefined && numero != '' && numero != null && !isNaN(numero)) {

                        await this.facturaNuevo({
                            ...facturas,
                            numero_control: numero,
                            serial_impresora: this.serialFiscal,
                        })

                        if (facturas._servicio && !ind_devolucion) {
                            if (Number(facturas._servicio.pvp) > 0) {
                                this.imprimirDocumentoNoFiscal(
                                    'Total Venta:           Bs ' +
                                    Number(facturas.total).toFixed(2) +
                                    ';Total Servicio (10%)  Bs ' +
                                    Number(facturas._servicio.pvp).toFixed(2) +
                                    ';Total:                Bs ' +
                                    (Number(facturas.total) + Number(facturas._servicio.pvp)).toFixed(2),
                                    store.state.impresoraFiscal,
                                )
                            }
                        }
                        return true

                    } else {
                        Vue.swal.showValidationMessage(`Debe proporcionar un numero valido`)
                    }
                } catch (error) {

                    store.commit('setAlert', {
                        message: `Error facturaNuevo : ${error}`,
                        type: 'error',
                        error: error,
                        funcion: 'errorFiscal',
                    })
                    return false
                }
            },
            allowOutsideClick: () => {
                !Vue.swal.isLoading()
            },
        }).then(result => {
            //GetFiscalizando(false)
        })
    }

    facturaNuevo = async facturas => {
        return await ComandaService.nuevaFactura(facturas)
            .then(response => {
                try {
                    if (response.data.mensaje == 'GUARDADA CON EXITO') {


                        store.commit('setAlert', {
                            message: `factura registrada con exito: ${response.data.datos.numero_control}`,
                            type: 'success',
                        })

                        return response.data.datos

                    } else {
                        store.commit('setAlert', {
                            message: `Verificar si se realizo la factura: ${response.data.datos.numero_control}`,
                            type: 'warning',
                        })
                        return null
                    }

                } catch (error) {
                    store.commit('setAlert', {
                        message: `hubo un error: ${error}`,
                        type: 'error',
                        error: error,
                        funcion: 'facturaNuevo',
                    })
                    return null
                }
            })
            .catch(error => {
                store.commit('setAlert', {
                    message: `Disculpe hubo un error: ${error}`,
                    type: 'error',
                    error: {
                        ...error,
                        response: error?.response?.data
                    },
                    funcion: 'facturaNuevo',
                })
                return null
            })
    }
    sleep(ms) {
        return new Promise(resolve => setTimeout(resolve, ms));
    }

    Fiscalizar = async (_props = {
        item,
        _MonedaPais,
        _serialFiscal,
        _ind_devolucion: false,
        _imprimir_tikera: true
    }
    ) => {


        this.cargando = true
        this.ind_devolucion = _props._ind_devolucion
        this.MonedaPais = _props._MonedaPais // {id:2,tasa:29.10}  
        this.serialFiscal = _props._serialFiscal
        this.comandaFiscalizada = { ..._props.item, devolucion_value: _props._ind_devolucion }



        if (!this.ind_devolucion) {
            await ComandaService.listarComandaAgrupado({ id: _props.item.id })
                .then(async response => {
                    console.log(response)
                    if (response.data.mensaje == 'BUSQUEDA_EXITOSA') {
                        await this.Totalizar({ ...response.data.datos, numero_control: this.comandaFiscalizada.numero_control }, _props._imprimir_tikera)
                    }
                })
                .catch(error => {
                    console.log(error)
                })
        } else {
            await ComandaService.listarFacturaLmpresora({ id: _props.item.id })
                .then(async response => {

                    if (response.data.mensaje == 'BUSQUEDA_EXITOSA') {
                        await this.DevolverFactura(response.data.datos)
                    }
                })
                .catch(error => {
                    console.log(error)
                })
        }

    }



    DevolverFactura = async datos => {
        const impresora = { ...store.state.impresoraFiscal }
        const factura = {
            ...datos,
            nFactura: datos.nFactura.toString(),
            montoRecDesc: datos.montoRecDesc.toString(),
            impresora: { ...impresora },
        }

        if (funciones.configuracionSelect(20) == '1') {
        if (!(store.state.impresoraFiscalSelect.id > 0)) {
            await ImpresoraService.imprimirFactura({
                factura: factura, pIsDevolucion: true, pAbrirGaveta: false,
                indIgtf: funciones.configuracionSelect(2) > 0 ? true : false,
            })
                .then(async response => {
                    try {
                        if ((response.data.estatus = true)) {
                            if (Number(response.data.datos.lastDocument) > 0) {
                                await this.facturaNuevo({
                                    ...factura,
                                    numero_control: response.data.datos.lastDocument,
                                    serial_impresora: this.serialFiscal,
                                })
                            } else {
                                await this.errorFiscal({
                                    ...factura,
                                    serial_impresora: this.serialFiscal,
                                })
                            }
                        } else {
                            await this.errorFiscal({
                                ...factura,
                                serial_impresora: this.serialFiscal,
                            })
                        }

                    } catch (error) {
                        await this.errorFiscal({
                            ...factura,
                            serial_impresora: this.serialFiscal,
                        })
                    }
                })
                .catch(error => {
                    store.commit('setAlert', {
                        message: `Disculpe hubo un error: ${error}`,
                        type: 'error',
                        error: {
                            ...error,
                            response: error?.response?.data
                        },
                        funcion: 'DevolverFactura',
                    })
                    console.log(error)
                })
        } else {
            await ComandaService.ImprimirFiscal({
                factura: factura,
                id_comanda: factura.nFactura,
                devolucion: true,
                abrir_gaveta: false,
                ind_igtf: funciones.configuracionSelect(2) > 0 ? true : false,
                id_impresora_fiscal: store.state.impresoraFiscalSelect.id,
                id_caja: store.state.user.caja.id,
            })
                .then(response => {
                    if (response.data.mensaje == 'guardado con exito') {
                        store.commit('setAlert', {
                            message: 'Se envio a la impresora fiscal ',
                            type: 'success',
                        })
                    } else {
                        store.commit('setAlert', {
                            message: 'Error al enviar a fiscalizar ' + response.data.mensaje,
                            type: 'error',
                            error: {
                                response: response.data,
                            },
                            funcion: 'ImprimirFiscal',
                        })
                    }


                })
                .catch(error => {
                    store.commit('setAlert', {
                        message: 'Error al enviar a fiscalizar ' + error,
                        type: 'error',
                        error: {
                            ...error,
                            response: error?.response?.data,
                        },
                        funcion: 'cerrarCuenta',
                    })

                })

        }
    }else{
        const facturas = factura;
        console.log("facturas", facturas)

        const _fact = await this.facturaNuevo({
            ...facturas
        })

        console.log("_fact",_fact)

        let _datos = {
            mediacarta: funciones.configuracionSelect(20) == '2' ? false : true,
            nombreDocumento: 'nombre',
            cabecera1: {
                visible: true,
            },
            titulo: {
                visible: true,
                posicion: 'center',
                texto: 'Recibo #1000',
                TamanoLetras: 14,
                color: '#FF0000',
            },
            etiqueta: {
                visible: false,
                valor: 'PAGADA',
                color: '#FF0000',
            },
            numeroDocumento: {
                texto: 'NOTA DE CREDITO: ',
                valor: (_fact.numero_documento.toString()).padStart(10, '0'),

            },
            numeroControl: {
                texto: 'REF: ',
                valor: 'COM-' + facturas.nFactura ,
            },
            numeroFecha: {
                texto: `Factura afectada: ${facturas.devolucion.numControlContraDev.toString().padStart(10, '0') }, Fecha: `,
                valor: moment(_fact.fecha).format('DD-MM-YYYY'),
            },
            sub1: {
                linea1: [
                    { texto: 'Nombre/Razon social:', valor: facturas.cliente.nombre },
                    { texto: 'CI/Rif:', valor: facturas.cliente.cedula },
                    { texto: 'Telf:', valor: '' },
                    { texto: 'Direccion:', valor: facturas.cliente.direccion ? facturas.cliente.direccion : '' },
                ],
                linea2: [],
            },
            sub2: {
                linea1: [

                ],
                linea2: [

                ],
            },
            detalles: {
                cabecera: [
                    { texto: 'Cod', posicion: '45', alineacion: 'left' },
                    {
                        texto: 'Descripcion',
                        posicion: '80',
                        alineacion: 'left',
                        justificar: { maxWidth: 200, align: 'justify', maximoItems: 30 },
                    },
                    { texto: 'Cantidad', posicion: '330', alineacion: 'right' },
                    { texto: 'Precio', posicion: '400', alineacion: 'right' },
                    { texto: 'Desc', posicion: '450', alineacion: 'right' },
                    { texto: 'IVA', posicion: '490', alineacion: 'right' },
                    { texto: 'Total', posicion: '555', alineacion: 'right' },
                ],

                cuerpo: [],
            },
            pie: {
                nota: {
                    texto: '',
                    valor: ''
                },
                totales: [
                    {
                        texto: 'SubTotal:',
                        valor: 'BS ' + funciones.formatoNumeric(facturas.sub_total - facturas.descuento),
                    },
                    {
                        texto: 'IVA:',
                        valor: 'BS ' + funciones.formatoNumeric(facturas.iva),
                    },
                    {
                        texto: 'Total:',
                        valor: 'BS ' + funciones.formatoNumeric((facturas.total )),
                    },
                ],
            },
        }


        facturas.producto.forEach(el => {
           
            _datos.detalles.cuerpo.push([(el.id_producto).toString(), el.descripcion, funciones.formatoNumeric(el.cantidad,2), 'Bs ' + funciones.formatoNumeric(el.pvp,2), funciones.formatoNumeric(Number(el.por_descuento),0) + '%', funciones.formatoNumeric(el.iva,0) + '%', 'Bs ' +funciones.formatoNumeric((el.total - el.iva_total) ,2)])
        })

        console.log(_datos)

        //caso de facturacion formato libre
        if (funciones.configuracionSelect(20) == '2' || funciones.configuracionSelect(20) == '3') { //formato carta completa

            this.generarPDF(_datos)
        }
    }
    }

    Totalizar = async (item, enviarFiscal = true) => {
        this.comandaFiscalizada = item
        let descuento = 0.0
        let servicio = 0.0
        let iva = 0.0
        let igtf = 0.0
        let subtotal = 0.0
        let total = 0.0
        let resta = 0.0
        let totalPagos = 0.0

        let descuentoMonedaPais = 0.0
        let servicioMonedaPais = 0.0
        let ivaMonedaPais = 0.0
        let igtfMonedaPais = 0.0
        let subtotalMonedaPais = 0.0
        let totalMonedaPais = 0.0
        let restaMonedaPais = 0.0
        let totalPagosMonedaPais = 0.0
        let totalPagosMoneda = 0.0
        let RecargoivaMonedaPais = 0.0
        let RecargoIva = 0.0
        let RecargoMonedaPais = 0.0
        let Recargo = 0.0

        this.comandaFiscalizada.productos.forEach(element => {
            const PrecioRealMonedaPais_ = this.round(element.precio_real * this.MonedaPais.tasa)
            const PrecioTotalMonedaPais_ = this.round(PrecioRealMonedaPais_ * element.cantidad)
            const descuentoMonedaPais_ = this.round(PrecioTotalMonedaPais_ * (element.por_descuento / 100))
            let recargoMonedaPais_ =
                (PrecioTotalMonedaPais_ - descuentoMonedaPais_) * (this.comandaFiscalizada.por_comision / 100)
            const ivaMonedaPais_ =
                (PrecioTotalMonedaPais_ - descuentoMonedaPais_) * (element.producto.impuesto_tasa_valor / 100)

            subtotalMonedaPais += PrecioTotalMonedaPais_
            descuentoMonedaPais += descuentoMonedaPais_
            ivaMonedaPais += ivaMonedaPais_
            RecargoMonedaPais += recargoMonedaPais_

            const PrecioReal_ = this.round(element.precio_real)
            const PrecioTotal_ = this.round(PrecioReal_ * element.cantidad)
            const descuento_ = this.round(PrecioTotal_ * (element.por_descuento / 100))

            let recargo_ = this.round((PrecioTotal_ - descuento_) * (this.comandaFiscalizada.por_comision / 100))
            const iva_ = this.round((PrecioTotal_ - descuento_) * (element.producto.impuesto_tasa_valor / 100))
            descuento += descuento_
            iva += iva_
            subtotal += PrecioTotal_
            Recargo += recargo_
        })

        this.comandaFiscalizada.pagos.forEach(element => {
            if (element.monto > 0) {
                const totalPagosMonedaPais_ = Number((Number(element.monto_real) * this.MonedaPais.tasa).toFixed(2))
                const totalPagos_ = Number(Number(element.monto_real).toFixed(2))
                const totalPagosMoneda_ = Number(Number(element.monto_moneda).toFixed(2))

                const igtfMonedaPais_ = Number((Number(Number(element.igtf)) * this.MonedaPais.tasa).toFixed(2))
                const igtf_ = Number(Number(element.igtf).toFixed(2))

                totalPagosMonedaPais += totalPagosMonedaPais_
                totalPagos += totalPagos_
                totalPagosMoneda += totalPagosMoneda_
                igtfMonedaPais += igtfMonedaPais_
                igtf += igtf_
            }
        })
        servicioMonedaPais = this.round(RecargoMonedaPais)
        totalMonedaPais = this.round(
            subtotalMonedaPais - descuentoMonedaPais + ivaMonedaPais + igtfMonedaPais, // + servicioMonedaPais,
        )
        restaMonedaPais = this.round(totalMonedaPais - totalPagosMonedaPais)

        servicio = this.round(Recargo)
        total = this.round(subtotal - descuento + iva + igtf + servicio)
        resta = total - totalPagos
        console.log('servicio aquii')
        console.log(this.round((subtotal - descuento) * (this.comandaFiscalizada.por_comision / 100)))

        let diferencia = this.round(total * this.MonedaPais.tasa - totalMonedaPais)



        servicioMonedaPais = this.round(
            (subtotalMonedaPais - descuentoMonedaPais) * (this.comandaFiscalizada.por_comision / 100),
        )

        totalMonedaPais = this.round(
            subtotalMonedaPais - descuentoMonedaPais + ivaMonedaPais + igtfMonedaPais, //+ servicioMonedaPais,
        )
        restaMonedaPais = this.round(totalMonedaPais - totalPagosMonedaPais)

        this.comandaFiscalizada = {
            ...this.comandaFiscalizada,
            descuento,
            servicio,
            iva,
            igtf,
            total,
            totalPagos,
            resta,
            subtotal,
            descuentoMonedaPais,
            servicioMonedaPais,
            ivaMonedaPais,
            igtfMonedaPais,
            subtotalMonedaPais,
            totalMonedaPais,
            restaMonedaPais,
            totalPagosMonedaPais,
            diferencia,
            RecargoMonedaPais,
            Recargo,
        }
        let devolucion = {}
        if (this.comandaFiscalizada.factura != null) {
            devolucion = {
                nFacturaAfectada: this.comandaFiscalizada.id.toString(),
                numControlContraDev: this.comandaFiscalizada.factura.numero_control,
                fechaFactContDev: new Date(),
                serialFactContDev: '',
            }
        }
        let ctdProductos = 0
        this.comandaFiscalizada.productos.forEach(item => {
            ctdProductos += item.cantidad
        })
        let factura = {
            nFactura: this.comandaFiscalizada.id.toString(),
            cajero: this.comandaFiscalizada.comandero_nombre,
            ctdProductos: Number(ctdProductos.toFixed(2)),
            sub_total: Number(this.comandaFiscalizada.subtotalMonedaPais), //+ this.comandaFiscalizada.servicioMonedaPais + diferencia,
           
            recargo: '0', //this.comandaFiscalizada.servicioMonedaPais,
            descuento: Number(this.comandaFiscalizada.descuentoMonedaPais),
            igtf: Number(this.comandaFiscalizada.igtfMonedaPais),
            total: this.comandaFiscalizada.totalMonedaPais, //+ diferencia,
            total_pago: this.comandaFiscalizada.totalPagosMonedaPais,
            vuelto: this.comandaFiscalizada.totalPagosMonedaPais - this.comandaFiscalizada.totalMonedaPais,
            iva: this.comandaFiscalizada.ivaMonedaPais,
            montoRecDesc: '0', //Number(this.comandaFiscalizada.servicioMonedaPais).toFixed(2).toString(),
            isPorcentajeRecDesc: false,
            isRecDesc: true,
            cliente: {
                id_cliente: this.comandaFiscalizada.id_cliente,
                direccion: this.comandaFiscalizada.direccion_cliente,
                nombre:
                    this.comandaFiscalizada.id_cliente == null
                        ? this.comandaFiscalizada.cliente
                        : [3, 4, 5].includes(this.comandaFiscalizada.id_tipo_cliente)
                            ? this.comandaFiscalizada.nombre_cliente + ' ' + this.comandaFiscalizada.apellido_cliente
                            : this.comandaFiscalizada.nombre_cliente,
                cedula: this.comandaFiscalizada.id_cliente == null ? '12345679' : this.comandaFiscalizada.documento,
            },
            impresora: { ...store.state.impresoraFiscal },
        }

        let producto = []

        this.comandaFiscalizada.productos.forEach(item => {

            let _valor  = {
                id_producto: item.producto.id,
                barra: item.producto.id.toString(),
                descripcion: item.producto.nombre,
                pvp: Number((item.precio_real * this.MonedaPais.tasa)),
                cantidad: Number(item.cantidad),
                sub_total: Number((Number((item.precio_real * this.MonedaPais.tasa)) * item.cantidad  )), 
                por_descuento: ((item.por_descuento )),
                
                total: Number((item.total * this.MonedaPais.tasa)),
                IVA: Number(item.producto.impuesto_tasa_valor),
                serial: '',
            }
            _valor.descuento =  (_valor.sub_total *  (item.por_descuento / 100)  )
            _valor.iva_total = ((_valor.sub_total - _valor.descuento )* (item.producto.impuesto_tasa_valor / 100)),
            _valor.total = Number((_valor.sub_total - _valor.descuento + _valor.iva_total ))

            producto.push(_valor)
        })

        if (this.comandaFiscalizada.por_comision / 100 > 0) {
            factura = {
                ...factura,
                _servicio: {
                    id_producto: 99999,
                    barra: '99999',
                    descripcion: 'SERVICIO',
                    pvp: servicioMonedaPais.toFixed(2),
                    cantidad: '1',
                    sub_total: servicioMonedaPais.toFixed(2),
                    descuento: '0',
                    iva_total: '0',
                    total: servicioMonedaPais,
                    IVA: '0',
                    descuento: '0',
                    serial: '',
                },
            }
        }

        let pagosFacturas = []
        let sum = 0
        this.comandaFiscalizada.pagos.forEach((item, i) => {
            if (item.monto > 0) {
                if (sum < Number(this.comandaFiscalizada.totalMonedaPais)) {
                    sum +=
                        item.moneda.id != this.MonedaPais.id
                            ? Number(this.MonedaPais.tasa) * Number(item.monto_real)
                            : Number(item.monto_moneda)
                    0
                    pagosFacturas.push({
                        id_moneda: item.moneda.id,
                        descripcion: item.moneda.nombre,
                        igtf: Number((item.igtf * this.MonedaPais.tasa).toFixed(2)),
                        monto:
                            Number(item.moneda.id != this.MonedaPais.id
                                ? (this.MonedaPais.tasa * item.monto_real).toFixed(2)
                                : item.monto_moneda.toFixed(2)),
                        tasa: Number(item.moneda.tipo_pago[0].ind_igtf == true ? this.MonedaPais.tasa.toFixed(2) : '1.00'),
                        formaPago: item.moneda.tipo_pago[0].id_fiscal,
                    })
                }
            }
        })
        let facturas = { ...factura, producto, pagosFacturas, devolucion_value: false }

        /* if (this.comandaFiscalizada.factura?.numero_control != undefined) {
             facturas = { ...facturas, devolucion: { ...devolucion }, devolucion_value: true }
         }*/
        if (enviarFiscal) {

            if (funciones.configuracionSelect(20) == '1') {
                //caso facturacion de maquina fiscal
                if (!(store.state.impresoraFiscalSelect.id > '0')) {
                    await ImpresoraService.imprimirFactura({

                        factura: facturas,
                        pIsDevolucion: false,
                        pAbrirGaveta: false,
                        indIgtf: funciones.configuracionSelect(2) > 0 ? true : false,
                    })
                        .then(async response => {
                            try {
                                if ((response.data.estatus = true)) {
                                    if (Number(response.data.datos.lastDocument) > 0) {
                                        await this.facturaNuevo({
                                            ...facturas,
                                            numero_control: response.data.datos.lastDocument,
                                            serial_impresora: this.serialFiscal,
                                        })

                                        if (facturas._servicio) {
                                            if (Number(facturas._servicio.pvp) > 0) {
                                                await this.imprimirDocumentoNoFiscal(
                                                    'Total Venta:           Bs ' +
                                                    Number(facturas.total).toFixed(2) +
                                                    ';Total Servicio (10%)  Bs ' +
                                                    Number(facturas._servicio.pvp).toFixed(2) +
                                                    ';Total:                Bs ' +
                                                    (Number(facturas.total) + Number(facturas._servicio.pvp)).toFixed(2),
                                                    store.state.impresoraFiscal,
                                                )
                                            }
                                        }
                                    } else {
                                        await this.errorFiscal({
                                            ...facturas,
                                            serial_impresora: this.serialFiscal,
                                        })
                                    }
                                } else {
                                    await this.errorFiscal({
                                        ...facturas,
                                        serial_impresora: this.serialFiscal,
                                    })
                                }
                            } catch (error) {
                                await this.errorFiscal({
                                    ...facturas,
                                    serial_impresora: this.serialFiscal,
                                })
                            }
                        })
                        .catch(error => {
                            store.commit('setAlert', {
                                message: `Disculpe hubo un error: ${error}`,
                                type: 'error',
                                error: {
                                    ...error,
                                    response: error?.response?.data
                                },
                                funcion: 'Totalizar-fiscalizar',
                            })
                        })

                } else {
                    await ComandaService.ImprimirFiscal({
                        factura: facturas,
                        id_comanda: facturas.nFactura,
                        devolucion: false,
                        abrir_gaveta: false,
                        ind_igtf: funciones.configuracionSelect(2) > 0 ? true : false,
                        id_impresora_fiscal: store.state.impresoraFiscalSelect.id,
                        id_caja: store.state.user.caja.id,
                    })
                        .then(response => {
                            if (response.data.mensaje == 'guardado con exito') {
                                store.commit('setAlert', {
                                    message: 'Se envio a la impresora fiscal ',
                                    type: 'success',
                                })
                            } else {
                                store.commit('setAlert', {
                                    message: 'Error al enviar a fiscalizar ' + response.data.mensaje,
                                    type: 'error',
                                    error: {
                                        response: response.data,
                                    },
                                    funcion: 'ImprimirFiscal',
                                })
                            }


                        })
                        .catch(error => {
                            store.commit('setAlert', {
                                message: 'Error al enviar a fiscalizar ' + error,
                                type: 'error',
                                error: {
                                    ...error,
                                    response: error?.response?.data,
                                },
                                funcion: 'cerrarCuenta',
                            })

                        })

                }

            } else {
                console.log("facturas", facturas)

                const _fact = await this.facturaNuevo({
                    ...facturas
                })

                console.log("_fact",_fact)

                let _datos = {
                    mediacarta: funciones.configuracionSelect(20) == '2' ? false : true,
                    nombreDocumento: 'nombre',
                    cabecera1: {
                        visible: true,
                    },
                    titulo: {
                        visible: true,
                        posicion: 'center',
                        texto: 'Recibo #1000',
                        TamanoLetras: 14,
                        color: '#FF0000',
                    },
                    etiqueta: {
                        visible: false,
                        valor: 'PAGADA',
                        color: '#FF0000',
                    },
                    numeroDocumento: {
                        texto: 'FACTURA: ',
                        valor: (_fact.numero_documento.toString()).padStart(10, '0'),

                    },
                    numeroControl: {
                        texto: 'REF: ',
                        valor: 'COM-' + facturas.nFactura,
                    },
                    numeroFecha: {
                        texto: 'Fecha: ',
                        valor: moment(_fact.fecha).format('DD-MM-YYYY'),
                    },
                    sub1: {
                        linea1: [
                            { texto: 'Nombre/Razon social:', valor: facturas.cliente.nombre },
                            { texto: 'CI/Rif:', valor: facturas.cliente.cedula },
                            { texto: 'Telf:', valor: '' },
                            { texto: 'Direccion:', valor: facturas.cliente.direccion ? facturas.cliente.direccion : '' },
                        ],
                        linea2: [],
                    },
                    sub2: {
                        linea1: [

                        ],
                        linea2: [

                        ],
                    },
                    detalles: {
                        cabecera: [
                            { texto: 'Cod', posicion: '45', alineacion: 'left' },
                            {
                                texto: 'Descripcion',
                                posicion: '80',
                                alineacion: 'left',
                                justificar: { maxWidth: 200, align: 'justify', maximoItems: 30 },
                            },
                            { texto: 'Cantidad', posicion: '330', alineacion: 'right' },
                            { texto: 'Precio', posicion: '400', alineacion: 'right' },
                            { texto: 'Desc', posicion: '450', alineacion: 'right' },
                            { texto: 'IVA', posicion: '490', alineacion: 'right' },
                            { texto: 'Total', posicion: '555', alineacion: 'right' },
                        ],

                        cuerpo: [],
                    },
                    pie: {
                        nota: {
                            texto: '',
                            valor: ''
                        },
                        totales: [
                            {
                                texto: 'SubTotal:',
                                valor: 'BS ' + funciones.formatoNumeric(facturas.sub_total - facturas.descuento),
                            },
                            {
                                texto: 'IVA:',
                                valor: 'BS ' + funciones.formatoNumeric(facturas.iva),
                            },
                            {
                                texto: 'Total:',
                                valor: 'BS ' + funciones.formatoNumeric((facturas.total )),
                            },
                        ],
                    },
                }


                facturas.producto.forEach(el => {
                    _datos.detalles.cuerpo.push([(el.id_producto).toString(), el.descripcion, funciones.formatoNumeric(el.cantidad,2), 'Bs ' + funciones.formatoNumeric(el.pvp,2), funciones.formatoNumeric(Number(el.por_descuento),0) + '%', funciones.formatoNumeric(el.IVA,0) + '%', 'Bs ' +funciones.formatoNumeric((el.total - el.iva_total) ,2)])
                })

                console.log(_datos)

                //caso de facturacion formato libre
                if (funciones.configuracionSelect(20) == '2' || funciones.configuracionSelect(20) == '3') { //formato carta completa

                    this.generarPDF(_datos)
                }
                 
            }

        } else {
            await this.facturaNuevo({
                ...facturas,
                numero_control: this.comandaFiscalizada.numero_control,
                serial_impresora: this.serialFiscal,
            })
        }
    }





    rellenarEspacios = (valor, max = 15) => {
        let resp = ''

        if (valor.length < max) {
            const dif = max - valor.length
            let cant = 0
            let par = false
            if (dif.mod % 2 == 0) {
                par = true
                cant = dif / 2
            } else {
                cant = (dif - 1) / 2
            }
            cant++
            let espacios = ''

            for (let index = 0; index < cant; index++) {
                espacios += ' '
            }

            resp = espacios + (par ? ' ' : '') + valor + espacios
        } else {
            resp = valor
        }
        return resp.toUpperCase()
    }
    TamanoLetras = {
        texto: 10,
        titulo: 14,
        subtitulo: 12,
        comentario: 10,
    }
    datosInit = {
        mediacarta: true,
        nombreDocumento: 'nombre',
        cabecera1: {
            visible: true,
        },
        titulo: {
            visible: true,
            posicion: 'center',
            texto: 'Recibo #1000',
            TamanoLetras: 14,
            color: '#FF0000',
        },
        etiqueta: {
            visible: false,
            valor: 'PAGADA',
            color: '#FF0000',
        },
        numeroDocumento: {
            texto: 'FACTURA: ',
            valor: '0000000001',

        },
        numeroControl: {
            texto: 'REF: ',
            valor: 'COM-00-000939',
        },
        numeroFecha: {
            texto: 'Fecha: ',
            valor: '30-08-2024',
        },
        sub1: {
            linea1: [
                { texto: 'Nombre/Razon social:', valor: 'Sigo s.a' },
                { texto: 'CI/Rif:', valor: 'j-15414-1541' },
                { texto: 'Telf:', valor: '+58 201 302 22' },
                { texto: 'Direccion:', valor: 'CALLE JULIO LUIS BUFON  CASA NRO S/N SECTOR LOS P' },
            ],
            linea2: [],
        },
        sub2: {
            linea1: [

            ],
            linea2: [

            ],
        },
        detalles: {
            cabecera: [
                { texto: 'Cod', posicion: '45', alineacion: 'left' },
                {
                    texto: 'Descripcion',
                    posicion: '80',
                    alineacion: 'left',
                    justificar: { maxWidth: 200, align: 'justify', maximoItems: 30 },
                },
                { texto: 'Cantidad', posicion: '330', alineacion: 'right' },
                { texto: 'Precio', posicion: '400', alineacion: 'right' },
                { texto: 'Desc', posicion: '450', alineacion: 'right' },
                { texto: 'IVA', posicion: '490', alineacion: 'right' },
                { texto: 'Total', posicion: '555', alineacion: 'right' },
            ],

            cuerpo: [
                ['1358', 'TORTILLA DE MAIZPAQ 100UND PRUEBA DE NOMBRE DE PROTIDCTO Q NO SE PASE DE MAS', '30.00', 'BS 12.82', '0,00%', '16%', 'BS 446.14'],
                ['1358', 'TORTILLA DE MAIZPAQ 100UND', '30.00', 'BS 12.82', '0%', '16%', 'BS 446.14'],
                ['1358', 'TORTILLA DE MAIZPAQ 100UND', '30.00', 'BS 1.212.200.82', '10%', '16%', 'BS 1.212.200.82'],
                ['1358', 'TORTILLA DE MAIZPAQ 100UND', '30.00', 'BS 12.82', '0%', '16%', 'BS 446.14'],
                ['1358', 'TORTILLA DE MAIZPAQ 100UND', '30.00', 'BS 12.82', '10%', '16%', 'BS 446.14'],
                ['1358', 'TORTILLA DE MAIZPAQ 100UND', '30.00', 'BS 12.82', '0%', '16%', 'BS 446.14'],
                ['1358', 'TORTILLA DE MAIZPAQ 100UND', '30.00', 'BS 12.82', '0%', '16%', 'BS 446.14'],
                ['1358', 'TORTILLA DE MAIZPAQ 100UND', '30.00', 'BS 12.82', '0%', '16%', 'BS 446.14'],
                ['1358', 'TORTILLA DE MAIZPAQ 100UND', '30.00', 'BS 12.82', '0%', '16%', 'BS 446.14'],
                ['1358', 'TORTILLA DE MAIZPAQ 100UND', '30.00', 'BS 12.82', '0%', '16%', 'BS 446.14'],
                ['1358', 'TORTILLA DE MAIZPAQ 100UND', '30.00', 'BS 12.82', '0%', '16%', 'BS 446.14'],
                ['1358', 'TORTILLA DE MAIZPAQ 100UND', '30.00', 'BS 12.82', '0%', '16%', 'BS 446.14'],
            ],
        },
        pie: {
            nota: {
                texto: 'Nota:',
                valor:
                    'Nota complet a CALLE JULIO LUIS BUFON  CASA NRO S/N SECTOR LOS P CALLE JULIO LUIS BUFON  CASA NRO S/N SECTOR LOS P CALLE JULIO LUIS BUFON  CASA NRO S/N SECTOR LOS P',
            },
            totales: [
                {
                    texto: 'SubTotal:',
                    valor: '0,0000',
                },
                {
                    texto: 'IVA:',
                    valor: '0,0000',
                },
                {
                    texto: 'Total:',
                    valor: '0,0000',
                },
            ],
        },
    }


    lineas = {
        cabecera1: 50,
        cabecera2: 75,
        sub1: 0,
        sub2: 0,
        cuerpo1: 0,
    }
    reset = () => {
        this.lineas = {
            cabecera1: 42,
            cabecera2: 30,
            sub1: 0,
            sub2: 0,
            cuerpo1: 0,
        }
    }

    sumLinea = (tipo, valor) => {
        if (tipo == 1) {
            this.lineas.cabecera1 += valor
            return this.lineas.cabecera1
        } else if (tipo == 2) {
            this.lineas.cabecera2 += valor
            return this.lineas.cabecera2
        } else if (tipo == 3) {
            this.lineas.sub1 += valor
            return this.lineas.sub1
        } else if (tipo == 4) {
            this.lineas.sub2 += valor
            return this.lineas.sub2
        } else if (tipo == 5) {
            this.lineas.cuerpo1 += valor
            return this.lineas.cuerpo1
        } else {
            return 0
        }
    }
    proporcionLogo = (config.VUE_APP_PROPORCION_LOGO || process.env.VUE_APP_PROPORCION_LOGO).split(',')
    generarPDF = (datos = this.datosInit) => {
        const orientacion = datos.orientacion ? datos.orientacion : 'p'
        this.reset()
        console.log(store.state.configuracionGeneral)
        const pdf = new jsPDF('p', 'pt', 'letter', true)

        const _height = datos.mediacarta ? 368 * 1.2 : pdf.internal.pageSize.height


        if (datos.etiqueta && datos.etiqueta.visible) {


            pdf.setDrawColor(datos.etiqueta.color)
            pdf.setLineWidth(30)
            pdf.line(pdf.internal.pageSize.width - 125, -20, pdf.internal.pageSize.width + 20, 125)
            pdf.setLineWidth(1)
            pdf.setTextColor(255, 255, 255)
            pdf.setDrawColor(0, 0, 0)
            pdf.setFont('Courier', 'bold')
            pdf.text(this.rellenarEspacios(datos.etiqueta.valor, 15), pdf.internal.pageSize.width - 115, 0, {
                align: 'left',
                angle: -45,
            })
        }

        pdf.setFontSize(this.TamanoLetras.texto)
        pdf.setTextColor('#000000')
        pdf.setFont('Helvetica', 'bold')

        //  datos de la empresa


        //  datos de la empresa
        pdf.setFont('Helvetica', 'normal')
        //  cabecera 2

        pdf.setTextColor('#000000')
        pdf.setFont('Helvetica', 'bold')
        pdf.setFontSize(14)
        pdf.text(datos.numeroDocumento.texto + datos.numeroDocumento.valor, pdf.internal.pageSize.width - 50, this.sumLinea(2, 40), 'right')
        pdf.setFontSize(10)
        pdf.text(datos.numeroFecha.texto + datos.numeroFecha.valor, pdf.internal.pageSize.width - 50, this.sumLinea(2, 12), 'right')
        pdf.text(datos.numeroControl.texto + datos.numeroControl.valor, 40, this.sumLinea(1, 40))

        /* pdf.setTextColor('#FF0000')
         pdf.setFont('Helvetica', 'bold')
         pdf.setFontSize(16)
         pdf.text(datos.numeroDocumento.valor, 40 + datos.numeroDocumento.texto.length * 7.5, this.sumLinea(1, 0))
 */
        pdf.setFillColor(255, 255, 255)

        /* Sub Linea  1 */
        let rectsub1 = this.lineas.cabecera1
        this.lineas.cabecera2 = this.lineas.cabecera1
        const maxWidthJust1 = datos.sub1.linea2.length > 0 ? 215 : 430
        this.sumLinea(1, 11)
        datos.sub1.linea1.forEach(item => {
            pdf.setFont('Helvetica', 'bold')
            pdf.setTextColor('#000000')
            pdf.setFontSize(9)
            pdf.text(item.texto, 45, this.sumLinea(1, 12))
            pdf.setFont('Helvetica', 'normal')
            pdf.text(item.valor, 45 + item.texto.length * 6, this.sumLinea(1, 0), { maxWidth: maxWidthJust1, align: 'justify' })

            if (maxWidthJust1 == 430) {
                if (item.valor.length / 74 > 1) {
                    this.sumLinea(1, Math.ceil(item.valor.length / 74) * 12)
                    alert(Math.ceil(item.valor.length / 74))
                }
            } else {
                if (item.valor.length / 37 > 1) {
                    this.sumLinea(1, Math.ceil(item.valor.length / 37) * 12)
                }
            }
            pdf.setTextColor('#000000')
        })

        this.sumLinea(2, 11)
        datos.sub1.linea2.forEach(item => {
            pdf.setFont('Helvetica', 'bold')
            pdf.setTextColor('#000000')
            pdf.setFontSize(9)
            pdf.text(item.texto, 305, this.sumLinea(2, 12))
            pdf.setFont('Helvetica', 'normal')
            pdf.text(item.valor, 305 + item.texto.length * 6, this.sumLinea(2, 0), {
                maxWidth: maxWidthJust1 - item.texto.length * 6 + 35,
                align: 'justify',
            })

            pdf.setTextColor('#000000')

            if (item.valor.length / 37 > 1) {
                this.sumLinea(2, Math.ceil(item.valor.length / 37) * 12)
            }
        })

        this.lineas.cabecera1 = this.lineas.cabecera2 > this.lineas.cabecera1 ? this.lineas.cabecera2 : this.lineas.cabecera1
        this.lineas.cabecera2 = this.lineas.cabecera2 > this.lineas.cabecera1 ? this.lineas.cabecera2 : this.lineas.cabecera1

        const maxRect1 = this.lineas.cabecera1
        pdf.roundedRect(40, rectsub1 + 10, 520, maxRect1 - rectsub1, 3, 3)

        /* Sub Linea  1 */

        this.sumLinea(1, 3)
        /* Sub Linea  2 */

        if (datos.sub2.linea1.length > 0 || datos.sub2.linea2.length > 0) {
            let rectsub2 = this.lineas.cabecera1
            this.lineas.cabecera2 = this.lineas.cabecera1
            const maxWidthJust = datos.sub2.linea2.length > 0 ? 215 : 430
            this.sumLinea(1, 11)
            datos.sub2.linea1.forEach(item => {
                pdf.setFont('Helvetica', 'bold')
                pdf.setTextColor('#000000')
                pdf.setFontSize(9)
                pdf.text(item.texto, 45, this.sumLinea(1, 12))
                pdf.setFont('Helvetica', 'normal')
                pdf.text(item.valor, 45 + item.texto.length * 6, this.sumLinea(1, 0), { maxWidth: maxWidthJust, align: 'justify' })

                if (maxWidthJust == 430) {
                    if (item.valor.length / 74 > 1) {
                        this.sumLinea(1, Math.ceil(item.valor.length / 74) * 12)
                    }
                } else {
                    if (item.valor.length / 37 > 1) {
                        this.sumLinea(1, Math.ceil(item.valor.length / 37) * 12)
                    }
                }

                pdf.setTextColor('#000000')
            })

            this.sumLinea(2, 11)
            datos.sub2.linea2.forEach(item => {
                pdf.setFont('Helvetica', 'bold')
                pdf.setTextColor('#000000')
                pdf.setFontSize(9)
                pdf.text(item.texto, 305, this.sumLinea(2, 12))
                pdf.setFont('Helvetica', 'normal')
                pdf.text(item.valor, 305 + item.texto.length * 6, this.sumLinea(2, 0), {
                    maxWidth: maxWidthJust - item.texto.length * 6 + 35,
                    align: 'justify',
                })

                pdf.setTextColor('#000000')

                if (item.valor.length / 37 > 1) {
                    this.sumLinea(2, Math.ceil(item.valor.length / 37) * 12)
                }
            })

            this.lineas.cabecera1 = this.lineas.cabecera2 > this.lineas.cabecera1 ? this.lineas.cabecera2 : this.lineas.cabecera1
            this.lineas.cabecera2 = this.lineas.cabecera2 > this.lineas.cabecera1 ? this.lineas.cabecera2 : this.lineas.cabecera1

            const maxRect = this.lineas.cabecera1

            pdf.roundedRect(40, rectsub2 + 10, 520, maxRect - rectsub2, 3, 3)
        }
        /* Sub Linea  1 */

        /* pdf.roundedRect(
            40,
            this.lineas.cabecera1 + 12,
            520,
            (datos.detalles.cuerpo.length * 20 <= 380
                ? pdf.internal.pageSize.height - datos.pie.totales.length * 20 - (this.lineas.cabecera1 + 20)
                : pdf.internal.pageSize.height - 50 - this.lineas.cabecera1) /2 ,
            3,
            3,
        ) */

        pdf.roundedRect(40, this.lineas.cabecera1 + 32, 520, 0, 0, 0)
        pdf.setFont('Helvetica', 'bold')
        this.sumLinea(1, 25)
        datos.detalles.cabecera.forEach(item => {
            pdf.text(item.texto, item.posicion, this.sumLinea(1, 0), item.alineacion)
        })
        const vas = this.sumLinea(1, 10)
        /*
        pdf.text('Codigo', 45, this.sumLinea(1, 25), 'left')
        pdf.text('Nombre Articulo', 120, this.sumLinea(1, 0), 'left')
        pdf.text('Prec', 380, this.sumLinea(1, 0), 'right')
        pdf.text('Cant', 430, this.sumLinea(1, 0), 'right')
        pdf.text('IVA', 490, this.sumLinea(1, 0), 'right')
        pdf.text('Total', 555, this.sumLinea(1, 0), 'right')
  */

        datos.detalles.cuerpo.forEach((item, ix) => {
            pdf.setFont('Helvetica', 'normal')
            pdf.setFontSize(7)
            let linea_temp = this.sumLinea(1, 10)
            datos.detalles.cabecera.find((col, index) => {
                const just = col.justificar ? col.justificar : col.alineacion
                pdf.text(item[index].substring(0, 45), col.posicion, linea_temp, just)
                /*if (col.justificar && Math.ceil(item[index].length / col.justificar.maximoItems) > 2) {
                    this.sumLinea(1, (Math.ceil(item[index].length / col.justificar.maximoItems) - 3) * 10) 
                }*/
            })


        })

        if (this.lineas.cabecera1 >= _height - datos.pie.totales.length * 20 - 80) {
            pdf.addPage()
            this.lineas.cabecera1 = 30 // Restart height position
            this.lineas.cabecera2 = 30 // Restart height position

            pdf.roundedRect(
                40,
                this.lineas.cabecera1 + 12,
                520,
                776 - datos.pie.totales.length * 20 - (this.lineas.cabecera1 + 20),
                3,
                3,
            )
            pdf.roundedRect(40, this.lineas.cabecera1 + 32, 520, 0, 0, 0)
            pdf.setFont('Helvetica', 'bold')
            this.sumLinea(1, 25)
            datos.detalles.cabecera.forEach(item => {
                pdf.text(item.texto, item.posicion, this.sumLinea(1, 0), item.alineacion)
            })
        }

        /////////////////////////////////////////////////////////////////////////////////////

        this.lineas.cabecera1 = (_height - 70 - datos.pie.totales.length * 20)
        this.lineas.cabecera2 = (_height - 70 - datos.pie.totales.length * 20)

        //pdf.roundedRect(40, this.lineas.cabecera1, 270, 776 - this.lineas.cabecera1 , 6, 6)
        pdf.text(datos.pie.nota.texto + ' ' + datos.pie.nota.valor, 45, this.lineas.cabecera1 + 16, {
            maxWidth: 250,
            align: 'justify',
        })

        pdf.setFont('Helvetica', 'bold')
        pdf.setFontSize(10)

        datos.pie.totales.forEach(item => {
            pdf.roundedRect(420, this.lineas.cabecera2, 140, 20, 0, 0)
            pdf.text(item.texto, 415, this.lineas.cabecera2 + 16, 'right')
            pdf.text(item.valor, 555, this.lineas.cabecera2 + 16, 'right')
            this.sumLinea(2, 20)
        })

        /* const addFooters = doc => {
             const pageCount = doc.internal.getNumberOfPages()
             doc.setFont('helvetica', 'italic')
             doc.setFontSize(8)
 
             for (var i = 1; i <= pageCount; i++) {
                 doc.setPage(i)
                 doc.text('Pagina ' + String(i) + ' de ' + String(pageCount), 40, doc.internal.pageSize.height - 10, {
                     align: 'left',
                 })
 
                 pdf.text(
                     store.state.user.nombre + ' ' + store.state.user.apellido + ' ' + moment().format('MM/DD/YYYY, h:mm:ss a'),
                     doc.internal.pageSize.width - 40,
                     doc.internal.pageSize.height - 10,
                     'right',
                 )
             }
         }
 */
        //addFooters(pdf)
        // pdf.autoPrint();
        pdf.autoPrint();

        pdf.output('dataurlnewwindow');

        //pdf.save(`${datos.nombreDocumento}.pdf`)
    }

}
export default new Fiscalizar();