
 
<template>
  <v-row justify="center">
    <v-dialog v-model="dialog" persistent max-width="600px">
      <v-card>
        <v-card-title>
          <span class="text-h5">Cambiar forma de pago</span>
        </v-card-title>
        <v-card-text>
          <div class="secondary">
            <v-container>
              <v-row>
                <v-bottom-navigation
                  v-model="pago.moneda"
                  class="pt-2"
                  color="success"
                  background-color="transparent"
                  light
                >
                  <v-btn v-for="(item, index) in monedasFilter()" :key="index" :value="item" color="white" class="mx-2">
                    <span>{{ item.nombre }}</span>
                    {{ item.simbolo }}
                  </v-btn>
                </v-bottom-navigation>
              </v-row>
              <v-row>
                <v-col class="text-center py-0 my-0">
                  <v-btn
                    class="ma-2"
                    :color="item.id == pago.tipo_pago ? 'primary' : 'white'"
                    small
                    v-for="(item, index) in pago.moneda.tipo_pago"
                    :key="'btn-' + index"
                    @click="SelectFormaPago(item)"
                  >
                    {{ item.nombre }}
                  </v-btn>
                </v-col>
              </v-row>
            </v-container>
          </div>

          <v-simple-table dense>
            <template>
              <tbody>
                <tr>
                  <td>
                    <div class="d-flex align-center py-1">
                      <v-avatar :class="'v-avatar-light-bg primary--text'" size="32">
                        <span> {{ pago.moneda.simbolo }} </span>
                      </v-avatar>
                      <div class="d-flex flex-column ms-3">
                        <span :class="' d-block font-weight-semibold   text-truncate'" v-if="pago.moneda.tipo_pago">
                          {{ pago.moneda.tipo_pago.find(it => it.id == pago.tipo_pago).nombre }}
                        </span>
                        <small> Monto {{ pago.moneda.simbolo }}: {{ pago.monto_moneda }}</small>
                        <small v-if="pago.moneda.tasa != 1"
                          >Monto $: {{ pago.monto_real }}/ tasa: {{ pago.moneda.tasa }}</small
                        >
                        <small v-if="pago.referencia != ''"> Ref: {{ pago.referencia }}</small>
                      </div>
                    </div>
                  </td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="secondary" small @click="dialog = false"> Cerrar </v-btn>
          <v-btn color="primary" small @click="selectPuntoVenta()"> Guardar </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <FormaPagoPuntoVenta ref="FormaPagoPuntoVentaRef" />
  </v-row>
</template>

  
<script>
import { ref, watch } from '@vue/composition-api'
import FormasPagosGeneral from '../../../comanda/dashboard/componentes/FormasPagosGeneral.vue'
import ComandaService from '@/api/servicios/ComandaService'
import store from '@/store'
import FormaPagoPuntoVenta from '../../../comanda/dashboard/componentes/FormaPagoPuntoVenta.vue'
export default {
  components: {
    FormasPagosGeneral,
    FormaPagoPuntoVenta,
  },
  watch: {
    'pago.moneda': function (news, old) {
      this.SelectFormaPago(news.tipo_pago[0])
    },
  },
  setup(props, context) {
    const comanda_pagoINIT = {
      id: 0,
      moneda: { id: '', simbolo: '', tasa: 1 },
      tipo_pago: '',
      monto_moneda: '',
      monto: 0,
      referencia: '',
      monto_real: '',
      nuevo: true,
      fecha: new Date(Date.now() - new Date().getTimezoneOffset() * 60000).toISOString().substr(0, 10),
    }

    const FormaPagoPuntoVentaRef = ref(null)

    const dialog = ref(false)
    const monedas = ref([])
    const pago = ref(JSON.parse(JSON.stringify(comanda_pagoINIT)))
    const abrir = _dato => {
      dialog.value = true
      CargarDatosGenerales()
      pago.value = JSON.parse(JSON.stringify(_dato))
    }
    const filtrarTipoPago = () => {}

    const selectPuntoVenta = () => {
      if (tipoPagoSelect().ind_punto_venta) {
        FormaPagoPuntoVentaRef.value.abrir(pago.value, _dato => {
          GuardarPagos(_dato)
        })
      } else {
        GuardarPagos(pago.value)
      }
    }

    const tipoPagoSelect = () => {
      let valor = {
        ind_punto_venta: false,
        ind_referencia: false,
      }
      if (pago.value.moneda && pago.value.tipo_pago) {
        valor = pago.value.moneda.tipo_pago.find(it => it.id == pago.value.tipo_pago)
      }
      console.log(valor)
      return valor
    }

    const GuardarPagos = _item => {
      const datos = {
        id_comanda_in: _item.id_comanda,
        id_in: _item.id,
        tipo_pago_in: _item.tipo_pago,
        pago_moneda_in: _item.monto_moneda,
        tasa_in: _item.moneda.tasa,
        id_operador: store.state.user.id_empleado,
        ind_propina: _item.ind_propina,
      }

      ComandaService.comandaModificarPagoAdmin(datos)
        .then(response => {
          if (response.data.mensaje == 'COMANDA MODIFICADA CON EXITO') {
            console.log('comandaModificarPagoAdmin', response.data)
            context.emit('actualizar', '')
            dialog.value = false
          } else {
            store.commit('setAlert', {
              message: response.data,
              type: 'error',
              error: response,
              funcion: 'GuardarPagos',
            })
          }

          //MonedaSelect(monedas.value[0])
        })
        .catch(error => {
          console.log(error)
          store.commit('setAlert', {
            message: 'Hubo un error: ' + error,
            type: 'error',
            error: {
                        ...error, 
                        response: error?.response?.data
                    },
            funcion: 'GuardarPagos',
          })
        })
    }

    const CargarDatosGenerales = () => {
      ComandaService.obtenerDatosGeneralesComanda()
        .then(response => {
          monedas.value = response.data.datos.monedas
          //MonedaSelect(monedas.value[0])
        })
        .catch(error => {
          console.log(error)
          store.commit('setAlert', {
            message: 'Hubo un error: ' + error,
            type: 'error',
            error: {
                        ...error, 
                        response: error?.response?.data
                    },
            funcion: 'CargarDatosGenerales',
          })
        })
    }

    const monedasFilter = () => {
      return monedas.value?.filter(item => item.id != 4)
    }

    const SelectFormaPago = item => {
      pago.value.tipo_pago = item.id
      pago.value.monto_moneda = pago.value.monto_real * pago.value.moneda.tasa
    }
    return {
      dialog,
      abrir,
      monedas,
      GuardarPagos,
      monedasFilter,
      SelectFormaPago,
      pago,
      selectPuntoVenta,
      FormaPagoPuntoVentaRef,
    }
  },
}
</script>