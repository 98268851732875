<template>
  <div>
    <v-card>
      <v-card-title> Procesar cuentas por cobrar </v-card-title>
      <v-card-text ref="contenedorRef">
        <v-row>
          <v-col lg="8" md="8" sm="12" cols="12">
            <cabecera-empresa></cabecera-empresa>
          </v-col>
          <v-col lg="4" md="4" sm="12" cols="12">
            <div>
              <h2 class="pt-12 pb-2">
                Recibo # <small>{{ id < 0 ? 'Nuevo' : id }}</small>
              </h2>
              <span class="m-0 p-0"> Fecha creacion: {{ FuncionesGenerales.formatoFecha(fecha, 3) }}</span>
              <br />
              <span v-if="soloVer" class="m-0 p-0"> Empleado: {{ empleado.nombre }} {{ empleado.apellido }}</span>
            </div>
          </v-col>
        </v-row>
        <v-divider></v-divider>
        <v-row class="mt-4 mb-2">
          <v-col md="8" cols="12">
            <h4>
              Cliente
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn v-bind="attrs" v-on="on" icon color="warning" @click="reiniciar()">
                    <v-icon>{{ icons.mdiCached }}</v-icon>
                  </v-btn>
                </template>
                <span>Reiniciar</span>
              </v-tooltip>
            </h4>
            <buscar-cliente v-if="cliente.nombre == ''" @Getcliente="Getcliente"></buscar-cliente>
            <div v-if="cliente.nombre != ''">
              <span>Documento: {{ cliente.documento }} </span>
              <br />
              <span>Cliente: {{ FuncionesGenerales.camelCase(cliente.nombre + ' ' + cliente.apellido) }}</span>
              <br />
              <span>Telefono: {{ cliente.telefono }}</span>
              <br />
              <span>Direccion: {{ cliente.direccion }}</span>
            </div>
          </v-col>
          <v-col md="4" cols="12">
            <div class="mt-4" v-if="cliente.nombre != ''">
              <span>Total deuda: ${{ FuncionesGenerales.formatoNumeric(totalDeuda) }}</span>
              <br />
              <span>Desde: {{ FuncionesGenerales.formatoFecha(fechasComandas.desde) }} </span>
              <br />
              <span>Hasta: {{ FuncionesGenerales.formatoFecha(fechasComandas.hasta) }} </span>
              <br />
              <span>Cant Comandas: {{ CantComandas }} </span>
            </div>
          </v-col>
        </v-row>
        <v-divider></v-divider>
        <v-row class="mt-4">
          <v-col cols="12" md="8" lg="8">
            <v-btn v-if="!soloVer" class="mr-2" color="primary" x-small @click="Seleccionar(-1)">
              Seleccionar Todo
              <v-icon right>
                {{ icons.mdiSelectAll }}
              </v-icon>
            </v-btn>
            <v-btn v-if="!soloVer" class="mr-2" color="secondary" x-small @click="Seleccionar(0)">
              Deseleccionar Todo
              <v-icon right>
                {{ icons.mdiSelect }}
              </v-icon>
            </v-btn>
            <v-btn v-if="!soloVer" class="mr-2" color="info" x-small @click="Seleccionar(100)">
              Seleccionar Cantidad
              <v-icon right>
                {{ icons.mdiSelectGroup }}
              </v-icon>
            </v-btn>
          </v-col>
          <v-col cols="12" md="4" lg="4">
            <v-select
              :disabled="soloVer"
              v-model="gruposAdministacionSelect"
              :items="gruposAdministacion"
              label="Grupo Administracion"
              item-value="id"
              item-text="descripcion"
              dense
              outlined
            ></v-select>
          </v-col>
        </v-row>
        <v-row class="mb-4">
          <v-col>
            <div style="max-height: 400px; overflow: auto">
              <v-simple-table dense>
                <template v-slot:default>
                  <thead>
                    <tr>
                      <th class="text-left">#</th>
                      <th>Administracion</th>
                      <th class="text-right">Total Comanda</th>
                      <th class="text-right">Pagos</th>
                      <th class="text-right" colspan="2">Total</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="item in comandasFiltradas" :key="item.id">
                      <td>
                        <v-checkbox
                          hide-details="true"
                          v-model="item.totalizar"
                          :label="`# ${item.id}`"
                          @change="Totalizar()"
                          v-if="!soloVer"
                        ></v-checkbox>
                        <span v-if="soloVer">#{{ item.id }}</span> <br />
                        {{ FuncionesGenerales.formatoFecha(item.fecha, 3) }}
                      </td>
                      <td>{{ item.descripcion }}</td>
                      <td class="text-right">$ {{ FuncionesGenerales.formatoNumeric(item.total_por_cobrar) }}</td>
                      <td class="text-right">$ {{ FuncionesGenerales.formatoNumeric(item.total_pago_por_cobrar) }}</td>
                      <td class="text-right">
                        $ {{ FuncionesGenerales.formatoNumeric(item.total_por_cobrar - item.total_pago_por_cobrar) }}
                      </td>
                      <td>
                        <v-btn icon color="primary" @click="AbrirModalComanda(item)">
                          <v-icon>{{ icons.mdiEye }}</v-icon>
                        </v-btn>
                      </td>
                    </tr>
                  </tbody>
                </template>
              </v-simple-table>
            </div>
          </v-col>
        </v-row>
        <v-divider></v-divider>
        <v-row class="mt-1">
          <v-col cols="12" sm="6" md="8" lg="8">
            Pagos
            <modal-pagos
              v-if="!soloVer"
              :valorMaximo="totalCxC"
              :indAbono="true"
              :disabled="totalCxC == 0"
              :colorBtn="`info`"
              :TxtoBtn="'Registrar Pago'"
              :monedas="monedas"
              :pagos="pagos"
              @GetPagos="GetPagos"
            >
            </modal-pagos>
            <v-btn v-if="!soloVer" icon color="secundary" @click="ResetPagos()">
              <v-icon>{{ icons.mdiCached }}</v-icon>
            </v-btn>
            <br />
            <v-row class="mt-1">
              <v-list width="100%">
                <v-divider></v-divider>
                <template v-for="(item, index) in pagos">
                  <v-list-item :key="index">
                    <v-list-item-avatar size="30">
                      {{ item.moneda.simbolo }}
                    </v-list-item-avatar>

                    <v-list-item-content class="p-0 m-0">
                      <v-list-item-subtitle class="py-0 my-0">
                        <strong
                          ><small>{{ FuncionesGenerales.formatoFecha(item.fecha, 1) }}</small></strong
                        >
                      </v-list-item-subtitle>
                      <v-list-item-subtitle class="py-0 my-0">{{ item.nombre }}</v-list-item-subtitle>

                      <v-list-item-subtitle class="py-0 my-0"
                        >{{ item.moneda.tipo_pago.find(i => i.id == item.tipo_pago).nombre }} {{ item.moneda.simbolo }}:
                        {{ FuncionesGenerales.formatoNumeric(item.monto_moneda) }}
                      </v-list-item-subtitle>
                      <v-list-item-subtitle class="py-0 my-0" v-if="item.moneda.tasa != 1"
                        ><small
                          >Monto $: {{ FuncionesGenerales.formatoNumeric(item.monto_real) }}/ tasa:
                          {{ FuncionesGenerales.formatoNumeric(item.moneda.tasa) }}</small
                        >
                      </v-list-item-subtitle>

                      <v-list-item-subtitle class="py-0 my-0" v-if="item.referencia != ''"
                        ><small> Ref: {{ item.referencia }}</small>
                      </v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>
                  <v-divider :key="index"></v-divider>
                </template>
              </v-list>
            </v-row>
            <v-row> </v-row>
          </v-col>
          <v-col cols="12" sm="6" md="4" lg="4">
            <h5 class="ml-4">Totales</h5>

            <v-simple-table dense>
              <template>
                <tbody>
                  <tr v-if="!soloVer">
                    <td>Total deuda</td>
                    <td class="text-right">$ {{ FuncionesGenerales.formatoNumeric(totalDeuda) }}</td>
                  </tr>
                  <tr>
                    <td>Total a pagar</td>
                    <td class="text-right">$ {{ FuncionesGenerales.formatoNumeric(totalCxC) }}</td>
                  </tr>
                  <tr>
                    <td>Total pagos</td>
                    <td class="text-right">$ {{ FuncionesGenerales.formatoNumeric(totalPagos) }}</td>
                  </tr>

                  <tr v-if="!soloVer">
                    <td>
                      <h4><span class="error--text">Deuda restante</span></h4>
                    </td>
                    <td class="text-right">
                      <h4>
                        <span class="error--text">$ {{ FuncionesGenerales.formatoNumeric(totalRestante) }}</span>
                      </h4>
                    </td>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>
          </v-col>
        </v-row>
        <v-divider></v-divider>
        <v-row class="my-2">
          <v-col cols="12" class="text-center">
            <v-btn class="mr-2" color="secondary" @click="reiniciar()"> Limpiar </v-btn>
            <v-btn v-if="soloVer" color="info" class="white--text" @click="ImprimirPDF()">
              IMPRIMIR
              <v-icon right dark>
                {{ icons.mdiPrinter }}
              </v-icon>
            </v-btn>
            <v-btn class="mr-2" color="primary" @click="GuardarCuenta()" v-if="!soloVer"> Guardar </v-btn>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
    <modal-ver-comanda
      :comanda="comandaSelect"
      :soloVer="true"
      :openModal="openModalComanda"
      @GetOpenModal="GetOpenModalComanda"
    ></modal-ver-comanda>

    <procesar-listar ref="ProcesarListarRef" @GetItem="GetItem" class="mt-4"></procesar-listar>
    <pdf ref="pdfRef"></pdf>
  </div>
</template>
  
<script>
// eslint-disable-next-line object-curly-newline
import {
  mdiCheckboxBlankOutline,
  mdiCached,
  mdiSelect,
  mdiSelectAll,
  mdiSelectGroup,
  mdiEye,
  mdiPrinter,
} from '@mdi/js'
import BuscarCliente from '../../archivo/cliente/components/BuscarCliente.vue'
import CabeceraEmpresa from '@/components/CabeceraEmpresa.vue'
import FuncionesGenerales from '@/funciones/funciones'
import { ref, onBeforeMount, watch } from '@vue/composition-api'
import ModalVerComanda from '../../dashboard/componentes/ModalVerComanda.vue'
import ModalPagos from './components/ModalPagos.vue'
import MaestroService from '@/api/servicios/MaestroService'
import ComandaService from '@/api/servicios/ComandaService'
import PuestoService from '@/api/servicios/PuestoService'
import store from '@/store'
import ProcesarListar from './components/ProcesarListar.vue'
import Pdf from '@/components/Pdf.vue'
import moment from 'moment'
// demos

export default {
  components: { BuscarCliente, CabeceraEmpresa, ModalVerComanda, ModalPagos, ProcesarListar, Pdf },
  setup() {
    const cliente = ref({
      nombre: '',
      apellido: '',
      direccion: '',
      documento: '',
      telefono: '',
    })
    const ProcesarListarRef = ref(null)
    const MonedaPais = ref({})
    const openModalComanda = ref(false)
    const comandaSelect = ref({})
    const empleado = ref({})
    const soloVer = ref(false)
    const pdfRef = ref(false)
    const AbrirModalComanda = datos => {
      openModalComanda.value = true
      comandaSelect.value = datos
    }
    const GetOpenModalComanda = datos => {
      openModalComanda.value = false
    }
    const pagos = ref([])
    const gruposAdministacionSelect = ref(1)
    const gruposAdministacion = ref([])
    const fecha = ref(moment().format('YYYY-MM-DD'))
    const monedas = ref([])
    const datos = ref([])
    const totalCxC = ref(0.0)
    const totalDeuda = ref(0.0)
    const totalPagos = ref(0.0)
    const totalRestante = ref(0.0)
    const CantComandas = ref(0)
    const id = ref(-1)
    const fechasComandas = ref({ desde: '', hasta: '' })
    const reiniciar = () => {
      cliente.value = {
        nombre: '',
        apellido: '',
        direccion: '',
        documento: '',
        telefono: '',
      }

      datos.value = []
      ResetPagos()
      totalCxC.value = 0.0
      totalDeuda.value = 0.0
      totalPagos.value = 0.0
      totalRestante.value = 0.0
      soloVer.value = false
      id.value = -1
      fecha.value = moment().format('YYYY-MM-DD')
      comandasFiltradas.value = []
    }
    onBeforeMount(() => {
      consultarMonedas()
      consultarGruposAdministracion()
    })
    const comandasFiltradas = ref([])
    const FiltrarGrupos = () => {
      comandasFiltradas.value = []
      totalDeuda.value = 0
      datos.value.forEach(item => {
        if (item.id_grupo_negocio == gruposAdministacionSelect.value || gruposAdministacionSelect.value == 0) {
          comandasFiltradas.value.push(item)
          totalDeuda.value = totalDeuda.value + (item.total_por_cobrar - item.total_pago_por_cobrar)
        }
      })
    }

    watch(gruposAdministacionSelect, () => {
      FiltrarGrupos()
      Totalizar()
    })

    const GetPagos = datos => {
      pagos.value = datos
      TotalizarPago(pagos.value)
    }

    const GetItem = datos_in => {
      console.log(datos_in)
      soloVer.value = true
      cliente.value = datos_in.cliente_json
      empleado.value = datos_in.empleado_json
      fecha.value = datos_in.fecha
      id.value = datos_in.id
      gruposAdministacionSelect.value == datos_in.id_grupo_negocio
      datos.value = datos_in.comandas
      console.log(datos.value)
      Seleccionar()
      CantComandas.value = 0
      totalDeuda.value = 0
      console.log('pagos:', datos_in.pagos)
      pagos.value = datos_in.pagos

      datos.value.forEach((item, i) => {
        totalDeuda.value = totalDeuda.value + (item.total_por_cobrar - item.total_pago_por_cobrar)
        CantComandas.value += 1
        if (i == 0) {
          fechasComandas.value.desde = item.fecha
          fechasComandas.value.hasta = item.fecha
        } else {
          if (item.fecha < fechasComandas.value.desde) {
            fechasComandas.value.desde = item.fecha
          }

          if (item.fecha > fechasComandas.value.hasta) {
            fechasComandas.value.hasta = item.fecha
          }
        }
      })
      FiltrarGrupos()
      Totalizar()
      TotalizarPago(datos_in.pagos)
    }

    const consultarMonedas = () => {
      MaestroService.listarMonedas()
        .then(response => {
          if (response.data.mensaje == 'BUSQUEDA_EXITOSA') {
            monedas.value = response.data.datos
            MonedaPais.value = monedas.value.find(item => item.meneda_pais)
          }
        })
        .catch(err => {
          console.log(err)
        })
        .finally(() => {})
    }

    const consultarGruposAdministracion = () => {
      PuestoService.obtenergrupoNegocio()
        .then(response => {
          if (response.data.mensaje == 'BUSQUEDA_EXITOSA') {
            response.data.datos.forEach(item => {
              gruposAdministacion.value.push(item)
            })
          }
        })
        .catch(err => {
          console.log(err)
        })
        .finally(() => {})
    }

    const consultarComandas = () => {
      totalDeuda.value = 0
      CantComandas.value = 0
      ComandaService.listarCuestasXCobrarPendientes({ id_cliente: cliente.value.id })
        .then(response => {
          if (response.data.mensaje == 'BUSQUEDA_EXITOSA') {
            datos.value = response.data.datos
            datos.value.forEach((item, i) => {
              totalDeuda.value = totalDeuda.value + (item.total_por_cobrar - item.total_pago_por_cobrar)
              CantComandas.value += 1
              if (i == 0) {
                fechasComandas.value.desde = item.fecha
                fechasComandas.value.hasta = item.fecha
              } else {
                if (item.fecha < fechasComandas.value.desde) {
                  fechasComandas.value.desde = item.fecha
                }

                if (item.fecha > fechasComandas.value.hasta) {
                  fechasComandas.value.hasta = item.fecha
                }
              }
            })
            FiltrarGrupos()
            ResetPagos()
            Seleccionar()
          }
        })
        .catch(err => {
          console.log(err)
        })
        .finally(() => {})
    }
    const TotalizarPago = _pagos => {
      pagos.value = _pagos
      console.log('TotalizarPago', _pagos)
      totalPagos.value = 0
      _pagos.forEach(item => {
        totalPagos.value = totalPagos.value + Number(item.monto_real)
      })

      totalRestante.value = totalDeuda.value - totalPagos.value
    }
    const Totalizar = () => {
      totalCxC.value = 0
      ResetPagos()
      comandasFiltradas.value.forEach(item => {
        if (item.totalizar) totalCxC.value = totalCxC.value + +(item.total_por_cobrar - item.total_pago_por_cobrar)
      })
      totalRestante.value = totalDeuda.value - totalPagos.value
    }

    const Seleccionar = (cantidad = -1) => {
      ResetPagos()
      if (cantidad == -1) {
        totalCxC.value = 0
        comandasFiltradas.value.forEach(item => {
          item.totalizar = true
          totalCxC.value = totalCxC.value + (item.total_por_cobrar - item.total_pago_por_cobrar)
        })
      } else if (cantidad == 0) {
        totalCxC.value = 0
        comandasFiltradas.value.forEach(item => {
          item.totalizar = false
        })
      } else {
        totalCxC.value = 0
        let total = 0
        comandasFiltradas.value.forEach(item => (item.totalizar = false))
        comandasFiltradas.value.forEach(item => {
          if (cantidad >= total + (item.total_por_cobrar - item.total_pago_por_cobrar)) {
            total += item.total_por_cobrar - item.total_pago_por_cobrar
            totalCxC.value = totalCxC.value + (item.total_por_cobrar - item.total_pago_por_cobrar)
            item.totalizar = true
          }
        })
      }

      totalRestante.value = totalDeuda.value - totalPagos.value
    }
    const guardando = ref(false)
    const GuardarCuenta = () => {
      let json = []
      if (pagos.value.length > 0) {
        guardando.value = true
        json = {
          comandas: comandasFiltradas.value.filter(item => item.totalizar),
          id_cliente: cliente.value.id,
          id_empleado: store.state.user.id_empleado,
          id_grupo_negocio: gruposAdministacionSelect.value,
          pagos: pagos.value,
        }
        ComandaService.pagoNuevoCuentasPorCobrar(json)
          .then(response => {
            if (response.data.mensaje == 'PAGO GUARDADO CON EXITO') {
              reiniciar()
              ProcesarListarRef.value.cargarDatosNuevo()
              store.commit('setAlert', {
                message: `Exito, ${response.data.mensaje}`,
                type: 'success',
              })
            } else {
              store.commit('setAlert', {
                message: `Ups, ${response.data.mensaje}`,
                type: 'error',
                error: response,
                funcion: 'GuardarCuenta',
              })
            }
          })
          .catch(err => {
            store.commit('setAlert', {
              message: `Ups, ${err}`,
              type: 'error',
              error: {
                        ...err, 
                        response: error?.response?.data
                    },
              funcion: 'GuardarCuenta',
            })
          })
          .finally(() => {
            guardando.value = false
          })
      } else {
        store.commit('setAlert', {
          message: 'No tiene pagos registrados',
          type: 'warning',
        })
      }

      console.log(json)
    }

    const Getcliente = dato => {
      cliente.value = dato
      consultarComandas()
    }
    const ResetPagos = () => {
      pagos.value = []
      totalPagos.value = 0
      totalRestante.value = totalDeuda.value - totalPagos.value
    }
    const contenedorRef = ref(null)
    const ImprimirPDF = () => {
      const cuerpoTablaDetalles = []
      const cuerpoTablaPagos = []

      comandasFiltradas.value.forEach(item => {
        cuerpoTablaDetalles.push([
          item.id,
          FuncionesGenerales.formatoFecha(item.fecha, 3),
          item.descripcion,
          (item.total_por_cobrar - item.total_pago_por_cobrar).toFixed(2),
        ])
      })

      pagos.value.forEach(item => {
        cuerpoTablaPagos.push([
          item.moneda.nombre,
          item.moneda.tipo_pago.find(i => i.id == item.tipo_pago).nombre,
          FuncionesGenerales.formatoFecha(item.fecha, 1),
          item.referencia,
          item.monto_moneda.toFixed(2),
          item.moneda.tasa.toFixed(2),
          item.monto_real.toFixed(2),
        ])
      })

      const datosInit = {
        nombreDocumento: `Recibo #${id.value} cuenta por cobrar ${cliente.value.nombre} ${cliente.value.apellido}`,
        cabecera1: {
          visible: true,
        },
        titulo: {
          visible: true,
          posicion: 'center',
          texto: 'Cuestas por cobrar',
          TamanoLetras: 14,
          color: '#7B7B7B',
        },
        cabecera2: {
          visible: true,
          datos: [
            { texto: 'Recibo #' + id.value, TamanoLetras: 14, color: '#FF0000' },
            { texto: 'Fecha: ' + FuncionesGenerales.formatoFecha(fecha.value, 1) },
            { texto: 'Hora: ' + FuncionesGenerales.formatoFecha(fecha.value, 2) },
          ],
        },
        sub1: {
          visible: true,
          datos: [
            { texto: 'Documento: ' + cliente.value.documento },
            {
              texto:
                'Cliente: ' +
                FuncionesGenerales.camelCase(cliente.value.nombre) +
                ' ' +
                FuncionesGenerales.camelCase(cliente.value.apellido),
            },
            { texto: 'Telefono:' + cliente.value.telefono },
            { texto: 'Direccion:' + cliente.value.direccion },
          ],
        },
        sub2: {
          visible: true,
          datos: [
            { texto: 'Total deuda: $' + totalDeuda.value.toFixed(2) },
            { texto: 'Desde: ' + FuncionesGenerales.formatoFecha(fechasComandas.value.desde) },
            { texto: 'Hasta: ' + FuncionesGenerales.formatoFecha(fechasComandas.value.hasta) },
            { texto: 'Cant Comandas: ' + CantComandas.value },
          ],
        },
        cuerpo1: {
          visible: true,
          datos: [
            {
              titulo: { texto: 'Detalles', TamanoLetras: 12 },
              columnStyles: {
                3: {
                  halign: 'right',
                },
              },
              tablas: {},
              head: [
                [
                  { content: '#', styles: { halign: 'left' } },
                  { content: 'Fecha', styles: { halign: 'left' } },
                  { content: 'Administracion', styles: { halign: 'left' } },
                  { content: 'Total', styles: { halign: 'right' } },
                ],
              ],
              body: cuerpoTablaDetalles,
            },

            {
              titulo: { texto: 'Pagos', TamanoLetras: 12 },
              columnStyles: {
                4: {
                  halign: 'right',
                },
                5: {
                  halign: 'right',
                },
                6: {
                  halign: 'right',
                },
              },
              tablas: {},
              head: [
                [
                  { content: 'Moneda', styles: { halign: 'left' } },
                  { content: 'Tipo', styles: { halign: 'left' } },
                  { content: 'Fecha', styles: { halign: 'left' } },
                  { content: 'Referencia', styles: { halign: 'left' } },
                  { content: 'Monto Moneda', styles: { halign: 'right' } },
                  { content: 'Tasa', styles: { halign: 'right' } },
                  { content: 'Monto $', styles: { halign: 'right' } },
                ],
              ],
              body: cuerpoTablaPagos,
            },
            {
              titulo: { texto: 'Totales', TamanoLetras: 12 },
              columnStyles: {
                0: {
                  halign: 'left',
                },
                1: {
                  halign: 'right',
                },
              },
              tablas: {
                cant: 2,
                id: 1,
              },
              head: [
                [
                  { content: 'Descripcion', styles: { halign: 'left' } },
                  { content: 'Monto $', styles: { halign: 'right' } },
                ],
              ],
              body: [
                ['Total a pagar', totalCxC.value.toFixed(2)],
                ['Total Pago', totalPagos.value.toFixed(2)],
              ],
            },
          ],
        },
      }

      pdfRef.value.generarPDF(datosInit)
    }

    return {
      icons: {
        mdiCached,
        mdiSelect,
        mdiSelectAll,
        mdiSelectGroup,
        mdiCheckboxBlankOutline,
        mdiEye,
        mdiPrinter,
      },
      Getcliente,
      cliente,
      reiniciar,
      datos,
      FuncionesGenerales,
      Seleccionar,
      GetOpenModalComanda,
      openModalComanda,
      AbrirModalComanda,
      comandaSelect,
      pagos,
      monedas,
      MonedaPais,
      GetPagos,
      ResetPagos,
      totalCxC,
      totalDeuda,
      totalRestante,
      totalPagos,
      Totalizar,
      CantComandas,
      fechasComandas,
      gruposAdministacion,
      gruposAdministacionSelect,
      FiltrarGrupos,
      GuardarCuenta,
      GetItem,
      empleado,
      soloVer,
      fecha,
      id,
      ImprimirPDF,
      contenedorRef,
      pdfRef,
      ProcesarListarRef,
      comandasFiltradas,
    }
  },
}
</script>
  